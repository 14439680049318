import React, { type ReactNode } from 'react';

/** Navigation that slides in from the top of the screen. */
export interface MenuPullDownDarkProps {
    active: boolean;
    children?: ReactNode;
}

export const MenuPullDownDark: React.FC<MenuPullDownDarkProps> = ({ children, active }) => {
    const height = active ? '' : '-translate-y-full';

    return <div className={`${MenuPullDownDarkBase} ${height}`}>{children}</div>;
};

const MenuPullDownDarkBase =
    'bg-platinum-850 duration-500 ease-in-out fixed justify-between overflow-auto bottom-0 top-0 transition-transform transform z-40 w-full items-start shadow';
