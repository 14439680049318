/**
 * Returns a normalized target element selector for the given step, taking into account
 * whether the user is using a mobile-sized device.
 *
 * @param step number of the step which will use the returned target selector
 * @param baseSelector base element target selector
 * @param isMobile whether the user is using a mobile device
 * @returns the normalized target selector
 */
export const getTourStepTargetSelector = (step: number, baseTargetSelector: string, isMobile: boolean): string => {
    return `${baseTargetSelector}-step-${step}${isMobile ? '-mobile' : ''}`;
};
