import React, { useContext } from 'react';

import { Logo } from '~/components/modules/Logo/Logo';
import { AppCtx } from '~/containers/AppContainer/context';

interface BlankNavigationProps {
    containerClass?: string;
    logoClass?: string;
}

export const BlankNavigation: React.FC<BlankNavigationProps> = ({ containerClass, logoClass }) => {
    const settings = useContext(AppCtx);
    const menuShadowClass = settings?.themeType === 'trading' ? '' : 'shadow-menu';

    return (
        <>
            <a href={'#main-content'} className={'skip-nav'}>
                Skip to main content
            </a>
            <div className={`flex w-full px-10 ${menuShadowClass} ${containerClass ?? ''}`}>
                <div className={logoClass ?? ''}>
                    <Logo type={'standard'} />
                </div>
                <div className={'w-full flex justify-between'} />
            </div>
        </>
    );
};
