import React, { useCallback, useEffect, useState } from 'react';
import { Browsers, getBrowserIcon } from 'react-browser-icons';
import { browserName } from 'react-device-detect';
import Image from 'next/legacy/image';
import { useRouter } from 'next/router';

import standardLogoSvg from '~/assets/img/branding/v3-logo.svg';
import { AnalyticsEventNames } from '~/constants/AnalyticsEventMap';
import { SessionStorageHelper } from '~/helpers/StorageHelper';
import { useTracking } from '~/hooks/tracking/useTracking';
import { Button, Modal, Text } from '~/vinovest-design-system/components';

const BrowserIcon = () => getBrowserIcon({ browser: browserName as Browsers, className: 'rounded-lg', size: 52 });
const setNativeBannerShown = () => SessionStorageHelper.setItem('native-banner-shown', true);

export enum NativeBannerEvents {
    Open = 'nativeBannerOpen',
    Proceed = 'nativeBannerProceed',
    Close = 'nativeBannerClose'
}

export default function NativeBanner() {
    const [isOpen, setIsOpen] = useState(false);
    const [isDisplayCookieSet, setIsDisplayCookieSet] = useState(false);
    const router = useRouter();
    const { captureEvent } = useTracking();

    useEffect(() => {
        if (!isOpen && !isDisplayCookieSet) {
            setTimeout(() => setIsOpen(true), 2000);
        }
    }, []);

    useEffect(() => {
        const openEvent = new CustomEvent(NativeBannerEvents.Open);

        if (isOpen) {
            window.dispatchEvent(openEvent);
        }
    }, [isOpen]);
    const openWebToApp = useCallback(() => {
        setNativeBannerShown();
        const continueEvent = new CustomEvent(NativeBannerEvents.Proceed);
        const closeEvent = new CustomEvent(NativeBannerEvents.Close);
        window.dispatchEvent(continueEvent);
        setIsOpen(false);
        setIsDisplayCookieSet(true);
        captureEvent(AnalyticsEventNames.OpenApp, { screen_name: router.pathname });
        window.dispatchEvent(closeEvent);
    }, [setIsOpen, captureEvent, router.pathname]);

    const continueOnBrowser = useCallback(() => {
        setNativeBannerShown();
        const closeEvent = new CustomEvent(NativeBannerEvents.Close);
        window.dispatchEvent(closeEvent);
        captureEvent(AnalyticsEventNames.ContinueWeb, { screen_name: router.pathname });
        setIsOpen(false);
        setIsDisplayCookieSet(true);
    }, [setIsOpen, captureEvent, router.pathname]);

    const dismissModal = useCallback(() => {
        setNativeBannerShown();
        const closeEvent = new CustomEvent(NativeBannerEvents.Close);
        window.dispatchEvent(closeEvent);
        captureEvent(AnalyticsEventNames.ContinueWeb, { screen_name: router.pathname });
        setIsOpen(false);
        setIsDisplayCookieSet(true);
    }, [setIsOpen, captureEvent, router.pathname]);

    return (
        <Modal open={isOpen} setOpen={dismissModal} position={'bottom'} isCloseBtn={false} fullPage={false}>
            <Text as={'p'} className={'text-center text-black leading-9'}>
                Vinovest is better in...
            </Text>
            <hr className={'border-solid border-1 border-neutrals-200'} />
            <div className={'flex flex-col m-1 py-4 bg-red'}>
                <div className={'flex flex-row items-center w-full justify-between mb-4'}>
                    <div className={'flex flex-row items-center'}>
                        <Image
                            priority
                            src={standardLogoSvg}
                            alt={'vinovest.co'}
                            objectFit={'cover'}
                            height={'52'}
                            width={'52'}
                            className={'rounded-lg'}
                        />
                        <Text as={'p'} className={'text-center text-14 ml-3'}>
                            Vinovest App
                        </Text>
                    </div>
                    <Button classnames={'rounded-3xl h-10 w-24 capitalize px-0'} onClick={openWebToApp}>
                        Open
                    </Button>
                </div>
                <div className={'flex flex-row items-center w-full justify-between'}>
                    <div className={'flex flex-row items-center'}>
                        <BrowserIcon />
                        <Text as={'p'} className={'text-center text-14 ml-3 text-sm'}>
                            {browserName}
                        </Text>
                    </div>
                    <Button classnames={'rounded-3xl h-10 w-24 capitalize px-0'} onClick={continueOnBrowser}>
                        Continue
                    </Button>
                </div>
            </div>
        </Modal>
    );
}
