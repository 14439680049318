import { useCallback } from 'react';
import Link from 'next/link';

interface HrefMenuItem extends MenuItemInternal {
    onClick?: undefined;
    href: string;
}

interface ClickableMenuItem extends MenuItemInternal {
    onClick: () => void;
    href?: undefined;
}

export type MenuItem = HrefMenuItem | ClickableMenuItem;

interface MenuItemInternal {
    display: string | JSX.Element;
    active?: boolean;
    variant?: 'light' | 'dark';
}

export const SubLink = ({ href, onClick, display, active, variant = 'dark' }: MenuItem) => {
    const onKeyDown = useCallback(
        (event: React.KeyboardEvent<HTMLDivElement>) => {
            if (event.key === 'Enter' && onClick) {
                onClick();
            }
        },
        [onClick]
    );

    const activeClass = active
        ? `${variant == 'light' ? 'text-brown-300' : 'text-neutrals-900'}`
        : `${variant == 'light' ? 'opacity-[.5]' : 'text-neutrals-400'}`;
    const classes = `font-vinovest text-14 py-2 ${activeClass} ${variant == 'light' && 'text-brown-300'} `;

    if (href) {
        return (
            <div className={classes}>
                <Link href={href}>{display}</Link>
            </div>
        );
    }

    if (onClick) {
        return (
            <div
                className={`cursor-pointer ${classes}`}
                tabIndex={0}
                onClick={onClick}
                role={'link'}
                onKeyDown={onKeyDown}
            >
                {display}
            </div>
        );
    }

    return null;
};
