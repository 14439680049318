import { PropsWithChildren } from 'react';

import { Box, Flex, Text } from '~/vinovest-design-system/components';

export const OnboardingTourTooltipBody: React.FC<PropsWithChildren<Record<string, unknown>>> = ({
    children
}: PropsWithChildren<Record<string, unknown>>) => {
    return (
        <Flex classnames={'p-1 flex-col sm:flex-row bg-neutrals-100 pl-0'}>
            <Box>
                <Text classnames={'font-normal text-black text-14'}>{children}</Text>
            </Box>
        </Flex>
    );
};
