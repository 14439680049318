import { FC, PropsWithChildren } from 'react';

import { Flex } from '~/vinovest-design-system/components';

interface ModalBodyProps {
    bodyClassNames?: string;
}

export const ModalBody: FC<PropsWithChildren<ModalBodyProps>> = ({
    bodyClassNames,
    children
}: PropsWithChildren<ModalBodyProps>) => <Flex classnames={bodyClassNames}>{children}</Flex>;
