import { ApplicationNavigation } from '~/components/modules/Navigation';
import { BlankNavigation } from '~/components/modules/Navigation/BlankNavigation';
import { useAuthentication } from '~/hooks/useAuthentication';
import { MarketingNavigationDark } from '../Navigation/MarketingNavigationDark';
import { useRouter } from 'next/router';
import { baseRoutes } from '~/routes/base';
import { MobileMarketingNavigationDark } from '../Navigation/MobileMarketingNavigationDark';

export const Header: React.FC<{ blank?: boolean }> = ({ blank }) => {
    const { isAuthenticated } = useAuthentication();
    const router = useRouter();
    const hasBgColor = !router.pathname.endsWith(baseRoutes.home.href);
    if (isAuthenticated) {
        return <ApplicationNavigation />;
    }

    return (
        <div
            className={`print:hidden sticky ${hasBgColor && 'bg-platinum-850'}`}
            id={'Header'}
            style={{ position: '-webkit-sticky', top: 0, zIndex: 20 }}
            key={'Header'}
        >
            {blank ? (
                <BlankNavigation />
            ) : (
                <>
                    <div className={'hidden lg:flex'}>
                        <MarketingNavigationDark />
                    </div>
                    <div className={'lg:hidden'}>
                        <MobileMarketingNavigationDark />
                    </div>
                </>
            )}
        </div>
    );
};
