import React from 'react';
import JoyRide, { CallBackProps, Step } from 'react-joyride';

import { OnboardingTourTooltip } from './components';
import { useOnboardingHandler } from './useOnboardingHandler';

const DEFAULT_SCROLL_OFFSET = 100;
const DEFAULT_SCROLL_DURATION = 400;

/** Types of onboarding experiences */
export enum OnboardingType {
    Welcome = 'welcome',
    Trading = 'trading',
    Dashboard = 'dashboard'
}

/** type representing keys used to persist information about user progress in an onboarding tour */
export interface TourLocalStorageKeys {
    stepIndexKey: string;
    isTourCompletedKey: string;
}

/** local storage keys used to store info about a user's progress in a tour */
export const OnboardingTourLocalStorageKeys: {
    [key in Exclude<OnboardingType, OnboardingType.Welcome>]: TourLocalStorageKeys;
} = {
    trading: {
        stepIndexKey: 'trading-tour-index',
        isTourCompletedKey: 'trading-tour-completed'
    },
    dashboard: {
        stepIndexKey: 'dashboard-tour-index',
        isTourCompletedKey: 'dashboard-tour-completed'
    }
};

/** local storage key used to record when the dashboard welcome modal is closed */
export const DASHBOARD_WELCOME_MODAL_WAS_CLOSED_LOCAL_STORAGE_KEY = 'dashboard-welcome-tour-completed';

export interface OnboardingTourContainerProps {
    /** the steps comprising the tour */
    steps: Step[];
    /** Local storage keys for storing the user's progress in the tour */
    variant: OnboardingType;
    /** optional callback invoked when the tour status changes */
    onTourStatusChange?: (stepChangeConfig: CallBackProps) => void;
}

/** Container component for an onboarding tour */
export const OnboardingTourContainer: React.FC<OnboardingTourContainerProps> = ({
    steps,
    variant,
    onTourStatusChange
}: OnboardingTourContainerProps) => {
    const { shouldRunTour, currentTourIndex, handleStepChange } = useOnboardingHandler({
        onTourStatusChange,
        variant
    });

    return (
        <>
            <JoyRide
                run={shouldRunTour}
                continuous
                stepIndex={currentTourIndex}
                showSkipButton
                scrollOffset={DEFAULT_SCROLL_OFFSET}
                scrollToFirstStep
                scrollDuration={DEFAULT_SCROLL_DURATION}
                steps={steps}
                callback={handleStepChange}
                floaterProps={{ disableAnimation: true }}
                tooltipComponent={OnboardingTourTooltip}
            />
        </>
    );
};

export default OnboardingTourContainer;
