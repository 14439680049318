import React from 'react';
import Image from 'next/legacy/image';

import twoGlasses2 from '~/assets/img/illustrations/Stat_wine_left_2.svg';
import whiskey from '~/assets/img/illustrations/whiskey-barrel.svg';
import { baseRoutes } from '~/routes/applicationRoutes';
import { Button } from '~/vinovest-design-system/components';

const FooterStartInvestingBanner = () => {
    return (
        <div className={'mt-10 w-full text-platinum-800 px-6 relative'}>
            <div className={'absolute h-1/2 bottom-0 left-0 right-0 bg-platinum-800'} />
            <div
                className={
                    'relative flex flex-col mx-auto md:py-20 pt-6 px-6 bg-brown-400 items-center z-10 container text-center max-w-[980px]'
                }
            >
                <h2 className={'text-45 lg:text-64 font-roslindale mb-10'}>Start investing in minutes</h2>
                <p className={'text-20 mb-10'}>Open an account, make a deposit, and start growing your wealth.</p>
                <Button
                    variant={'custom'}
                    href={baseRoutes.signup.href}
                    padding={'px-16'}
                    classnames={'bg-platinum-800 text-brown-300 h-[74px] text-[16px] flex items-center'}
                >
                    Start investing
                </Button>
                <div className={'absolute bottom-[-8px] left-7 hidden md:block w-[116px]'}>
                    <Image src={twoGlasses2} width={116} height={164} alt={'two glasses'} layout={'responsive'} />
                </div>
                <div className={'md:absolute bottom-[0px] right-7 mt-10 md:mt-0 h-[150px] w-full md:w-[150px]'}>
                    <div className={'relative h-[150px] w-[150px] mx-auto'}>
                        <Image
                            src={whiskey}
                            alt={'whiskey'}
                            layout={'fill'}
                            objectFit={'contain'}
                            className={'w-[150px] h-[150px] left-[50%] md:left-0'}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FooterStartInvestingBanner;
