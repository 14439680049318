import { useCallback, useEffect, useState } from 'react';
import { CallBackProps } from 'react-joyride';

import { OnboardingHelper } from '~/components/modules/OnboardingTour/OnboardingHelper';
import { OnboardingType } from '~/components/modules/OnboardingTour/OnboardingTourContainer';
import { NativeBannerEvents } from '~/components/views/NativeAppBanner/NativeAppBanner';
import { SessionStorageHelper } from '~/helpers/StorageHelper';
import { useAllSavedPaymentMethods } from '~/hooks/payments/useAllSavedPaymentMethods';
import { useTracking } from '~/hooks/tracking/useTracking';
import { useGetFundingStatus } from '~/hooks/useGetFundingStatus';

/**
 * Determines whether or not show the onboarding tour and returns callbacks for handling tour progress.
 * @param useOnboardingHandlerOptions - Options for the handler including status change callback and onboarding variant
 * @param useOnboardingHandlerOptions.onTourStatusChange - Callback for change in tour status
 * @param useOnboardingHandlerOptions.localStorageKeys - Local storage keys used to determine is onboarding has happened yet or not
 */
export const useOnboardingHandler = ({
    variant,
    onTourStatusChange
}: {
    variant: OnboardingType;
    onTourStatusChange?: (stepChange: CallBackProps) => void;
}) => {
    const { captureEvent } = useTracking();

    const { funded: isClientFunded } = useGetFundingStatus();

    const { paymentMethods, isSuccess } = useAllSavedPaymentMethods();

    const [currentTourIndex, setCurrentTourIndex] = useState(() => OnboardingHelper.getHelper(variant).getIndex() || 0);
    const [isNativeBannerOpen, setIsNativeBannerOpen] = useState(false);
    const [nativeDownloadBannerShown, setNativeDownloadBannerShown] = useState(
        SessionStorageHelper.getItem<string>('native-banner-shown')
    );
    const [shouldRunTour, setShouldRunTour] = useState(false);

    useEffect(() => {
        const handleNativeBannerShownEvent = () => {
            setNativeDownloadBannerShown('true');
            setIsNativeBannerOpen(false);
        };

        const handleNativeBannerOpenEvent = () => {
            setIsNativeBannerOpen(true);
        };

        window.addEventListener(NativeBannerEvents.Close, handleNativeBannerShownEvent, { once: false });
        window.addEventListener(NativeBannerEvents.Open, handleNativeBannerOpenEvent, { once: false });

        return () => {
            window.removeEventListener(NativeBannerEvents.Close, handleNativeBannerShownEvent);
            window.removeEventListener(NativeBannerEvents.Open, handleNativeBannerOpenEvent);
        };
    }, []);

    const handleStepChange = useCallback(
        (stepChangeConfig: CallBackProps) => {
            const tourUpdate = OnboardingHelper.getHelper(variant).handleTourUpdate(
                stepChangeConfig.index,
                stepChangeConfig.type,
                captureEvent
            );
            setCurrentTourIndex(tourUpdate);

            if (onTourStatusChange) {
                onTourStatusChange(stepChangeConfig);
            }
        },
        [variant, onTourStatusChange]
    );

    useEffect(() => {
        const tourNotCompleted = !OnboardingHelper.getHelper(variant).getCompleted();
        const hasPaymentMethods = paymentMethods.length > 0;
        const newAccount = !hasPaymentMethods && !isClientFunded && tourNotCompleted;

        const nativeBannerOpen = nativeDownloadBannerShown === 'false' || isNativeBannerOpen;
        const shouldShowOnboardingTour = isSuccess && newAccount && !nativeBannerOpen;

        setShouldRunTour(shouldShowOnboardingTour);
    }, [variant, isClientFunded, isSuccess, paymentMethods, nativeDownloadBannerShown]);

    return { shouldRunTour, setShouldRunTour, handleStepChange, currentTourIndex };
};
