import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { UrlModalHelper } from '~/helpers/UrlModalHelper';

export const useUrlModal = (urlKey: string) => {
    const [open, setOpen] = useState(false);
    const router = useRouter();

    const { isReady, query } = router;

    useEffect(() => {
        if (isReady && UrlModalHelper.isModalOpen(urlKey)) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [isReady, query, setOpen, router]);

    const toggleModal = () => {
        // Opened but should close
        if (open) {
            UrlModalHelper.closeModal(router, urlKey);
        }

        // Not Opened but should open
        if (!open) {
            UrlModalHelper.openModal(router, urlKey);
        }
    };
    const closeModal = () => {
        UrlModalHelper.closeModal(router, urlKey);
    };

    const openModal = () => {
        UrlModalHelper.openModal(router, urlKey);
    };

    return { open, toggleModal, closeModal, openModal };
};
