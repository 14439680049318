import React from 'react';
import styled from 'styled-components';

import { ROUTE_PATHS } from '~/utils/routePaths';
import Link from 'next/link';

const FooterDisclaimer = () => {
    return (
        <DisclaimerWrapper>
            <p>
                Vinovest,inc. (“Vinovest“) operates a website at vinovest.co and certain mobile apps (the “Platform“).
                By using the Platform, you accept our <Link href={ROUTE_PATHS.terms}> Terms of Service </Link>
                and <Link href={ROUTE_PATHS.privacyPolicy}>Privacy Policy</Link>. All images and return and projection
                figures shown are for illustrative purposes only, may assume additional investments over time, and are
                not actual Vinovest customer or model returns or projections. Past performance is no guarantee of future
                results. Any historical returns, expected returns, or probability projections may not reflect actual
                future performance. All purchases involve risk and may result in damages, partial or total loss. While
                the data we use from third parties is believed to be reliable, we cannot ensure the accuracy or
                completeness of data provided by involved third parties. Neither Vinovest nor any of its affiliates
                provide tax advice and do not represent in any manner that the outcomes described herein will result in
                any particular tax consequence. Prospective customers should confer with their personal tax advisors
                regarding the tax consequences based on their particular circumstances. Neither Vinovest nor any of its
                affiliates assume responsibility for the tax consequences for any customer.
            </p>
        </DisclaimerWrapper>
    );
};

const DisclaimerWrapper = styled.div`
    font-size: 12px;
    line-height: 1.5;
    margin: 100px auto 50px;
    max-width: 1440px;
    opacity: 0.5;
    a,
    p {
        color: #fae8d1;
    }
`;

export default FooterDisclaimer;
