/* eslint-disable no-nested-ternary,@typescript-eslint/no-use-before-define */
import React, { useContext, useEffect, useState } from 'react';
import Image from 'next/legacy/image';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import Link from 'next/link';

import arrowRight from '~/assets/img/navigation/arrow-right.svg';
import { Logo } from '~/components/modules/Logo/Logo';
import { AppCtx } from '~/containers/AppContainer/context';
import { PosthogHelper } from '~/helpers/PosthogHelper';
import { baseRoutes } from '~/routes/applicationRoutes';
import { AnalyticsEventNames } from '~/tracking';
import { Button } from '~/vinovest-design-system/components/Button';
import { MenuLinks } from '~/vinovest-design-system/components/Navigation';
import { getLoginRoute } from '~/routes/base';

import { NAVIGATION_LINKS_GUEST, NAVIGATION_SUB_LINKS } from './Navigation.data';

export const MarketingNavigation: React.FC = () => {
    const settings = useContext(AppCtx);
    const router = useRouter();
    const [loginDestination, setLoginDestination] = useState('');
    const menuShadowClass = settings?.themeType === 'trading' ? '' : '';
    const subLinkDescriptions = ['Democratizing Fine Wine Investing', 'How can we help you?', 'The Cellar'];
    const isPlatinum =
        router.pathname.includes(baseRoutes.trackRecord.href) || router.pathname.endsWith(baseRoutes.home.href);
    const isBrown400 = router.pathname.includes(baseRoutes.aboutUs.href);
    const isTurtleGreen800 = router.pathname.includes(baseRoutes.whyWhiskey.href);
    const navBackgroundColor =
        settings?.navBackgroundColor ??
        (isPlatinum
            ? 'bg-platinum-800'
            : isBrown400
              ? 'bg-brown-400'
              : isTurtleGreen800
                ? 'bg-turtleGreen-800'
                : 'bg-white');
    const linkColor = isPlatinum || isTurtleGreen800 ? 'text-brown-300' : '';
    const logoType = isPlatinum || isTurtleGreen800 ? 'light' : 'standard';

    useEffect(() => {
        if (router.pathname.startsWith(baseRoutes.upsell.href)) {
            /* should return to the current marketing page for these locations */
            setLoginDestination(window.location.pathname + window.location.search);
        }
    }, [router, setLoginDestination]);

    return (
        <>
            <a href={'#main-content'} className={'skip-nav'}>
                Skip to main content
            </a>
            <div className={`flex w-full px-10 md:h-[75px] ${menuShadowClass} ${navBackgroundColor}`}>
                <div className={'md:h-[75px] md:w-[75px]'}>
                    <Logo type={logoType} />
                </div>
                <div className={'w-full flex justify-between'}>
                    <MenuLinks classnames={'pl-9 flex items-center space-x-10'}>
                        {NAVIGATION_LINKS_GUEST.map(({ to, label }) => (
                            <MenuContainer
                                className={`font-mono cursor-pointer flex items-center text-14 capitalize ${linkColor} ${isPlatinum ? 'border-brown-300' : 'border-black'}`}
                                key={label}
                            >
                                <Link href={to} className={`font-mono ${label === 'HOW IT WORKS' ? '_xto_' : ''}`}>
                                    {label}
                                </Link>
                                {label === 'HOW IT WORKS' && (
                                    <SubNavLinksDiv>
                                        <SubNavLinks>
                                            {NAVIGATION_SUB_LINKS.map((item, index) => (
                                                <LinkItemLink key={item.label} href={item.to}>
                                                    <div>
                                                        <LinkLabelDiv>{item.label}</LinkLabelDiv>
                                                        <LinkDescriptionDiv>
                                                            {subLinkDescriptions[index]}
                                                        </LinkDescriptionDiv>
                                                    </div>
                                                    <div>
                                                        <Image
                                                            src={arrowRight.src}
                                                            width={14}
                                                            height={8}
                                                            alt={'arrow icon'}
                                                        />
                                                    </div>
                                                </LinkItemLink>
                                            ))}
                                        </SubNavLinks>
                                    </SubNavLinksDiv>
                                )}
                            </MenuContainer>
                        ))}
                    </MenuLinks>

                    <div className={'flex items-center'}>
                        <Button
                            variant={isPlatinum || isTurtleGreen800 ? 'brownBorderTransparent' : 'secondary'}
                            onClick={() => {
                                PosthogHelper.captureEvent(AnalyticsEventNames.UserSelectLogin);
                                router.push(getLoginRoute(loginDestination));
                            }}
                            classnames={'text-14 h-[40px]'}
                            padding={['px-5', 'py-2']}
                        >
                            Login
                        </Button>
                        <Button
                            variant={'green'}
                            onClick={() => router.push(baseRoutes.signup.href)}
                            classnames={'ml-5 text-14 md:h-[40px]'}
                            padding={['px-5', 'py-2']}
                        >
                            Get started
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

const SubNavLinks = styled.div`
    width: 300px;

    background: white;

    position: relative;

    border-radius: 10px;
    box-shadow: 0px 0px 203px rgba(0, 0, 0, 0.07);
    padding: 10px;

    &:after {
        position: absolute;
        height: 20px;
        width: 20px;

        content: ' ';

        top: -10px;
        left: 25px;
        transform: rotate(45deg);

        background: white;
    }
`;

const MenuContainer = styled.div`
    position: relative;

    &:hover,
    &:focus-within,
    &:focus {
        > div {
            visibility: visible;
            transition-delay: 0s;
        }
    }
`;

const SubNavLinksDiv = styled.div`
    z-index: 15;

    visibility: hidden;
    transition: visibility 0.2s 0.3s; /* delay on leave */

    top: 40px;
    position: absolute;
    color: black;
`;

const LinkItemLink = styled(Link)`
    align-items: center;
    border-bottom: 1px solid #eeeeee;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 15px 5px;
    &:last-child {
        border-bottom: none;
    }
`;

const LinkDescriptionDiv = styled.div`
    font-family: var(--font-vinovest-medium);
    font-size: 12px;
    padding-top: 0;
`;
const LinkLabelDiv = styled.div`
    font-size: 20px;
    font-family: var(--font-roslindale);
`;
