import type { TokenParams } from '@okta/okta-auth-js';

import { releaseHubPassword, releaseHubUsername } from '~/server/constants';

const localDevelopment = process.env.LOCAL_DEVELOPMENT;
/**
 * @param credentials - username and password to authenticate with for basic authentication
 */
export function getBasicAuthInit(credentials: { username: string; password: string }) {
    const { username, password } = credentials;
    const basicAuth = Buffer.from(`${username}:${password}`).toString('base64');

    const Authorization = `Basic ${basicAuth}`;
    const requestInit = {
        method: 'GET',
        headers: {
            Authorization
        }
    };
    return requestInit;
}

/**
 * Creates a regular expression of url pathanmes to test against.
 * @param paths - paths array to generate a regex that will match all url pathnames that start with these.
 */
export function getPathnameRegex(paths: string[]): RegExp {
    return new RegExp(`^\\/(${paths.join('|')}).*`);
}

/**
 * Get the endpoint for the webclient.
 */
export const getWebclientEndpoint = (): string => {
    if (!process.env.MIDDLEWARE_LOCAL_WEBCLIENT) {
        return process.env.MIDDLEWARE_RELEASEHUB_WEBCLIENT;
    }
    return localDevelopment ? process.env.MIDDLEWARE_LOCAL_WEBCLIENT : process.env.MIDDLEWARE_RELEASEHUB_WEBCLIENT;
};

/**
 * Get the request for the webclient.
 * @param pathname - pathanme of the request
 * @returns
 */
export const getWebclientFetchRequest = (pathname: string) => {
    const endpoint = getWebclientEndpoint();
    const requestUrl = `${endpoint}${pathname}`;
    const releaseHubRequest = fetch(
        requestUrl,
        getBasicAuthInit({ username: releaseHubUsername, password: releaseHubPassword })
    );
    const localWebclientRequest = fetch(requestUrl);

    if (!process.env.MIDDLEWARE_LOCAL_WEBCLIENT) {
        return releaseHubRequest;
    }

    return localDevelopment ? localWebclientRequest : releaseHubRequest;
};

export const getAuthUrl = (oktaApi: string, tokenParams: TokenParams) => {
    const baseUrl = `${process.env.NEXT_PUBLIC_OKTA_ISSUER}/v1/${oktaApi}`;
    const params = new URLSearchParams(tokenParams as any).toString();

    return `${baseUrl}?${params}`;
};
