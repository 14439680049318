import { createRoute } from 'next-typed-routes';

export enum AccountRoutes {
    AutoInvest = '/account/auto-invest',
    Email = '/account/email',
    InvestmentPlan = '/account/investment-plan',
    Password = '/account/password',
    Phone = '/account/phone',
    Sell = '/account/sell',
    Liquidate = '/account/liquidate',
    LiquidationQueue = '/account/liquidation-queue',
    Horizon = '/account/time-horizon',
    ActiveRebalance = '/account/active-rebalance',
    AutoPay = '/account/auto-pay',
    WithdrawalFunds = '/account/withdraw-funds'
}

export const accountRoutes = {
    autoInvest: createRoute(AccountRoutes.AutoInvest),
    email: createRoute(AccountRoutes.Email),
    investmentPlan: createRoute(AccountRoutes.InvestmentPlan),
    timeHorizon: createRoute(AccountRoutes.Horizon),
    activeRebalance: createRoute(AccountRoutes.ActiveRebalance),
    password: createRoute(AccountRoutes.Password),
    phone: createRoute(AccountRoutes.Phone),
    sell: createRoute(AccountRoutes.Sell),
    liquidate: createRoute(AccountRoutes.Liquidate),
    liquidationQueue: createRoute(AccountRoutes.LiquidationQueue),
    autoPay: createRoute(AccountRoutes.AutoPay),
    withdrawalFunds: createRoute(AccountRoutes.WithdrawalFunds)
};
