import { ROUTE_PATHS } from '~/utils/routePaths';

const data: { title: string; navItems: { title: string; path: string; isLink: boolean }[] }[] = [
    {
        title: 'Company',
        navItems: [
            {
                title: 'Home',
                path: ROUTE_PATHS.home,
                isLink: true
            },
            {
                title: 'About',
                path: ROUTE_PATHS.aboutUs,
                isLink: true
            },

            {
                title: 'Vinovest council',
                path: ROUTE_PATHS.council,
                isLink: true
            },
            {
                title: 'Careers',
                path: ROUTE_PATHS.careers,
                isLink: true
            },
            {
                title: 'Press',
                path: ROUTE_PATHS.press,
                isLink: true
            }
        ]
    },
    {
        title: 'Learn',
        navItems: [
            {
                title: 'Why wine',
                path: ROUTE_PATHS.whyWine,
                isLink: true
            },
            {
                title: 'Why whiskey',
                path: ROUTE_PATHS.whyWhiskey,
                isLink: true
            },
            {
                title: 'How it works',
                path: ROUTE_PATHS.howItWorks,
                isLink: true
            },
            {
                title: 'Pricing',
                path: ROUTE_PATHS.pricing,
                isLink: true
            },
            {
                title: 'Track Record',
                path: ROUTE_PATHS.trackRecord,
                isLink: true
            }
        ]
    },
    {
        title: 'Services',
        navItems: [
            {
                title: 'Guarantee',
                path: ROUTE_PATHS.satisfaction,
                isLink: true
            },

            {
                title: 'Sustainability',
                path: ROUTE_PATHS.sustainability,
                isLink: true
            },
            {
                title: 'Wine futures',
                path: ROUTE_PATHS.wineFutures,
                isLink: true
            },
            {
                title: 'Wine Directory',
                path: ROUTE_PATHS.wineDirectory,
                isLink: true
            }
        ]
    },
    {
        title: 'Resources',
        navItems: [
            {
                title: 'Blog',
                path: ROUTE_PATHS.blog,
                isLink: true
            },

            {
                title: 'Help',
                path: ROUTE_PATHS.help,
                isLink: true
            },
            {
                title: 'Contact us',
                path: ROUTE_PATHS.contactUs,
                isLink: true
            },
            {
                title: 'Privacy policy',
                path: ROUTE_PATHS.privacyPolicy,
                isLink: true
            },
            {
                title: 'T&C',
                path: ROUTE_PATHS.terms,
                isLink: true
            },
            {
                title: 'Accessibility',
                path: ROUTE_PATHS.accessibility,
                isLink: true
            }
        ]
    }
];
export default data;
