import { PropsWithChildren } from 'react';

import { Flex, Heading } from '~/vinovest-design-system/components';

export const OnboardingTourTooltipHeading: React.FC<PropsWithChildren<Record<string, unknown>>> = ({
    children
}: PropsWithChildren<Record<string, unknown>>) => (
    <Flex>
        <Heading level={'h6'}>{children}</Heading>
    </Flex>
);
