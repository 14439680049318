import React, { HTMLAttributes } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { AnalyticsEventMap } from '~/constants/AnalyticsEventMap';
import { useTracking } from '~/hooks/tracking/useTracking';
import { useScrollToTop } from '~/hooks/useScrollToTop';
import { ClassNamesProps } from '~/models/ClassNamesProps';

/** Individual menu links for all navigation. A menu link without a link can be used to toggle a SubMenu component. */
export interface MenuLinkNavProps extends HTMLAttributes<HTMLButtonElement>, ClassNamesProps {
    variant?: keyof typeof MenuLinkNavType;
    link: string;
    onClick?: () => void;
    track?: { eventName: keyof AnalyticsEventMap; metaData?: Record<string, string> };
}

const MenuLinkNavType = {
    custom: '',
    primary: 'transparent',
    active: 'bg-brown-300 text-platinum-800'
};

const MenuLinkBase = 'cursor-pointer flex items-center text-brown-300 font-vinovest-medium';

export const MenuLinkDark: React.FC<MenuLinkNavProps> = ({ children, link, variant, onClick, track }) => {
    useScrollToTop();
    const router = useRouter();
    const activeClass =
        (router.pathname.startsWith(link) && link != '/') || router.pathname === link ? 'active' : 'primary';
    const { captureEvent } = useTracking();

    const linkCallback = track?.eventName
        ? () => {
              captureEvent(track.eventName, track.metaData);
              onClick && onClick();
          }
        : onClick;

    return (
        // these are bubbling
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
        <li onClick={linkCallback} className={MenuLinkBase}>
            {link ? (
                <Link
                    href={link}
                    className={`flex items-center !hover:no-underline px-[20px] py-[5px] rounded-[100px] ${MenuLinkNavType[variant || activeClass]}`}
                >
                    {children}
                </Link>
            ) : (
                <>{children}</>
            )}
        </li>
    );
};
