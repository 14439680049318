import type { ReactNode } from 'react';

import { HourGlass } from '~/vinovest-design-system/components/Spinners/HourGlass';

export interface PageLoadProps {
    ready?: boolean;
    children?: ReactNode | undefined;
}

/**
 * This is the page redirect component that is displayed when doing a hard redirect
 * to the web-app.
 */
export const PageLoad: React.FC<PageLoadProps> = ({ ready = true, children }) => {
    return ready ? (
        <>{children}</>
    ) : (
        <div className={'flex flex-row justify-center min-h-screen min-w-screen'}>
            <div className={'flex flex-col justify-center min-h-screen min-w-screen'}>
                <HourGlass />
            </div>
        </div>
    );
};
