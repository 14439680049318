import { MouseEvent } from 'react';

import { Button } from '~/vinovest-design-system/components';

interface OnboardingTourTooltipSecondaryButtonProps {
    text: string;
    onClick: (e: MouseEvent<HTMLElement>) => void;
}

export const OnboardingTourTooltipSecondaryButton: React.FC<OnboardingTourTooltipSecondaryButtonProps> = ({
    text,
    onClick
}: OnboardingTourTooltipSecondaryButtonProps) => {
    return (
        <Button variant={'custom'} onClick={onClick} className={'text-14 py-1 h-10 rounded'}>
            {text}
        </Button>
    );
};
