/* eslint-disable no-nested-ternary,@typescript-eslint/no-use-before-define */
import React, { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';

import { Logo } from '~/components/modules/Logo/Logo';
import { AppCtx } from '~/containers/AppContainer/context';
import { PosthogHelper } from '~/helpers/PosthogHelper';
import { baseRoutes } from '~/routes/applicationRoutes';
import { AnalyticsEventNames } from '~/tracking';
import { Button } from '~/vinovest-design-system/components/Button';
import { MenuLinks } from '~/vinovest-design-system/components/Navigation';
import { getLoginRoute } from '~/routes/base';

import { NAVIGATION_LINKS_GUEST } from './Navigation.data';
import { MenuLinkDark } from '~/vinovest-design-system/components/Navigation/components/MenuLinkDark';

export const MarketingNavigationDark: React.FC = () => {
    const settings = useContext(AppCtx);
    const router = useRouter();
    const [loginDestination, setLoginDestination] = useState('');
    const [bgColor, setBgColor] = useState(false);
    const menuShadowClass = settings?.themeType === 'trading' ? '' : '';
    const isPlatinum = !router.pathname.endsWith(baseRoutes.home.href);
    const navBackgroundColor = settings?.navBackgroundColor ?? (isPlatinum ? 'bg-platinum-850' : 'bg-[transparent]');
    const linkColor = 'text-brown-300';
    const logoType = 'light';

    const handleScroll = () => {
        if (window.scrollY > 400) {
            setBgColor(true);
        } else {
            setBgColor(false);
        }
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    });

    useEffect(() => {
        if (router.pathname.startsWith(baseRoutes.upsell.href)) {
            /* should return to the current marketing page for these locations */
            setLoginDestination(window.location.pathname + window.location.search);
        }
    }, [router, setLoginDestination]);

    return (
        <>
            <a href={'#main-content'} className={'skip-nav'}>
                Skip to main content
            </a>
            <div
                className={`flex w-full px-10 md:h-[75px] max-w-[1730px] mx-auto ${menuShadowClass} ${navBackgroundColor} ${!isPlatinum ? ' transition-all duration-300' : ''} ${bgColor ? 'bg-platinum-850' : ''}`}
            >
                <div className={'w-full flex justify-between'}>
                    <MenuLinks classnames={'flex items-center w-[40%]'}>
                        {NAVIGATION_LINKS_GUEST.map(({ to, label }) => (
                            <MenuContainer
                                className={`font-vinovest-medium cursor-pointer flex items-center text-16 ${linkColor} ${isPlatinum ? 'border-brown-300' : 'border-black'}`}
                                key={label}
                            >
                                <MenuLinkDark link={to}>{label}</MenuLinkDark>
                            </MenuContainer>
                        ))}
                    </MenuLinks>

                    <div className={'md:h-[75px] md:w-[10%] md:max-w-[75px] flex justify-center'}>
                        <Logo type={logoType} />
                    </div>
                    <div className={'flex items-center justify-end w-[40%]'}>
                        <Button
                            variant={'platinumDark'}
                            onClick={() => {
                                PosthogHelper.captureEvent(AnalyticsEventNames.UserSelectLogin);
                                router.push(getLoginRoute(loginDestination));
                            }}
                            font={'font-vinovest-medium'}
                            classnames={'text-16 h-[40px] !capitalize'}
                            padding={['px-5', 'py-2']}
                        >
                            Login
                        </Button>
                        <Button
                            variant={'green'}
                            onClick={() => router.push(baseRoutes.signup.href)}
                            font={'font-vinovest-medium'}
                            classnames={'ml-5 text-16 md:h-[40px] !capitalize'}
                            padding={['px-5', 'py-2']}
                        >
                            Get started
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

const MenuContainer = styled.div`
    position: relative;

    &:hover,
    &:focus-within,
    &:focus {
        > div {
            visibility: visible;
            transition-delay: 0s;
        }
    }
`;
