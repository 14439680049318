import { useQuery } from 'react-query';

import { queries } from '~/constants/queryKeys';
import { VinovestNetworkingHelper } from '~/helpers/VinovestNetworkingHelper';
import { useAuthentication } from '~/hooks/useAuthentication';

/** Query for fetching a user's referral status. */
export const useGetFreeFeeReferralStatus = () => {
    const { isAuthenticated, userId } = useAuthentication();

    return useQuery(
        [queries.getUserReferralInfo, userId],
        async () => {
            const { managed } = VinovestNetworkingHelper.getClient();
            const result = await managed.getUserReferralProfileStatus();
            if (result.status !== 200) {
                return undefined;
            }
            return result.data;
        },
        {
            enabled: isAuthenticated && Boolean(userId)
        }
    );
};
