import { getPathnameRegex } from './helpers';

/** Unauthenticated exchange routes */
export const exchangePaths = [
    String.raw`trade(?!(\/marketplace))`,
    'api',
    'auth',
    'wine',
    '_next',
    'collections',
    'compare',
    'deposit',
    'fonts',
    'styles',
    'trade',
    'wine',
    'dashboard',
    'portfolio',
    '.well-known',
    'help',
    String.raw`new-user\/create-account`,
    'signup',
    'implicit/callback',
    'login',
    'help-category',
    'app-ads.txt',
    'request-password-reset',
    'passwordReset',
    'flags',
    'icons',
    'recommendation',
    'onboarding',
    'whiskey',
    'track-record',
    // 'why-wine',
    'whiskey-investment',
    'about-us',
    'internal',
    '',
    'offer',
    'blog'
];

/** Authenticated exchange routes */
export const protectedExchangeRoutes = [
    'dashboard',
    'deposit',
    'compare',
    'add-funds',
    String.raw`trade\/marketplace`,
    'documents',
    'account',
    'portfolio',
    'auth',
    'add-funds',
    'invite',
    'managed',
    String.raw`new-user\/investor-profile`,
    'before-you-liquidate-your-portfolio',
    'liquidation',
    'scheduled-wines'
];

export const nextAuthSecret = process.env.NEXTAUTH_SECRET;
export const releaseHubUsername = process.env.RELEASEHUB_USERNAME as string;
export const releaseHubPassword = process.env.RELEASEHUB_PASSWORD as string;

export const exchangePathsRegex = getPathnameRegex(exchangePaths);
export const protectedRoutesRegex = getPathnameRegex(protectedExchangeRoutes);
