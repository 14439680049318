import { TooltipRenderProps } from 'react-joyride';

import { Box, Button } from '~/vinovest-design-system/components';

import { useRouter } from 'next/router';
import { useTracking } from '~/hooks/tracking/useTracking';
import { MouseEvent as ReactMouseEvent, useCallback } from 'react';
import { AnalyticsEventNames } from '~/constants/AnalyticsEventMap';
import { ConnectSourceModalKey, UrlModalHelper } from '~/helpers/UrlModalHelper';
import { OnboardingTourTooltipHeading } from '~/components/modules/OnboardingTour/components/OnboardingTourTooltipHeading';
import { OnboardingTourTooltipBody } from '~/components/modules/OnboardingTour/components/OnboardingTourTooltipBody';
import { OnboardingTourTooltipFooter } from '~/components/modules/OnboardingTour/components/OnboardingTourTooltipFooter';
import { OnboardingTourTooltipSecondaryButton } from '~/components/modules/OnboardingTour/components/OnboardingTourTooltipSecondaryButton';

/** Generic tooltip used to display a single step within an onboarding tour */
export const OnboardingTourTooltip: React.FC<TooltipRenderProps> = (props: TooltipRenderProps) => {
    const { step, tooltipProps, primaryProps, skipProps } = props;
    const router = useRouter();
    const { captureEvent } = useTracking();

    const handlePrimaryButtonClick = useCallback(
        (e: ReactMouseEvent) => {
            // insert tracking and any other step-specific behavior
            captureEvent(AnalyticsEventNames.TooltipNext, { stage: 'payment_account' });
            primaryProps.onClick(e as ReactMouseEvent<HTMLElement>);
            UrlModalHelper.openModal(router, ConnectSourceModalKey);
        },
        [captureEvent, primaryProps, router]
    );

    const skipClick = (e: ReactMouseEvent<HTMLElement, MouseEvent>) => {
        captureEvent(AnalyticsEventNames.TooltipSkip, { stage: 'payment_account' });
        skipProps.onClick(e);
    };

    return (
        <div {...tooltipProps}>
            <Box classnames={'p-7 bg-neutrals-100 max-w-xs rounded-lg'}>
                <OnboardingTourTooltipHeading>Next Step</OnboardingTourTooltipHeading>
                <OnboardingTourTooltipBody>{step.content}</OnboardingTourTooltipBody>
                <OnboardingTourTooltipFooter>
                    <OnboardingTourTooltipSecondaryButton text={'Skip'} onClick={skipClick} />
                    <Button onClick={handlePrimaryButtonClick} variant={'green'} padding={'p-2'}>
                        <span className={'text-12'}>Connect Account</span>
                    </Button>
                </OnboardingTourTooltipFooter>
            </Box>
        </div>
    );
};
