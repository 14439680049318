import { pathToRegexp } from './pathToRegexp';

export interface PathMatchesOptions {
    exact: boolean;
    path?: string;
}

export const matchPathname = (pathnames: string[], pathToTest: string, exact = true): boolean => {
    const pathRegexp = pathToRegexp(pathnames, exact);
    return pathRegexp.test(pathToTest);
    return false;
};
