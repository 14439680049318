import { useState } from 'react';
import Cookies from 'js-cookie';

import { Currencies } from '~/constants/currencies';

export const getStoredCurrency = (): Currencies => {
    return (Cookies.get('localCurrency') as Currencies) ?? Currencies.USD;
};

/** Utility hook used to get and set the chosen display currency by a user */
export const useSwitchCurrencyCodes = () => {
    const [selectedCurr, setSelectedCurrency] = useState<Currencies>(getStoredCurrency());

    const handleCurrChange = (selected: React.SetStateAction<Currencies>) => {
        setSelectedCurrency(selected);
    };

    const handleCancelCurr = () => {
        const reset = getStoredCurrency();
        setSelectedCurrency(reset);
    };

    const handleSaveCurr = () => {
        Cookies.set('localCurrency', selectedCurr, { expires: 7 });
    };

    return {
        selectedCurr,
        handleCurrChange,
        handleCancelCurr,
        handleSaveCurr
    };
};
