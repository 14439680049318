/* eslint-disable no-nested-ternary */
import { Fragment, useContext, useMemo, useState } from 'react';
import { Dialog, Disclosure, Transition } from '@headlessui/react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { Logo } from '~/components/modules/Logo/Logo';
import { OnboardingTourTargetContainer } from '~/components/modules/OnboardingTour/components/OnboardingTourTargetContainer';
import { DASHBOARD_ONBOARDING_TOUR_STEP_ONE_BASE_SELECTOR } from '~/components/modules/OnboardingTour/Dashboard/DashboardOnboardingTour';
import { TrackEvent } from '~/components/modules/Tracking/TrackEvent';
import { AddFundsInitiators, AnalyticsEventNames } from '~/constants/AnalyticsEventMap';
import { DepositContext } from '~/context/Deposit/DepositContext';
import { ManagedPortfolioContext } from '~/context/ManagedPortfolioContext';
import { TradingPortfolioContext } from '~/context/TradingPortfolioContext';
import { DepositTransferModalKey, ReferralGlobal, UrlModalHelper } from '~/helpers/UrlModalHelper';
import { useManagedPortfolioQuery } from '~/hooks/queries/portfolios/managed/useManagedPortfolioQuery';
import { useAuthentication } from '~/hooks/useAuthentication';
import { useCustomPageTitle } from '~/hooks/useCustomPageTitle';
import { useWindowSize } from '~/hooks/useWindowSize';
import { PortfolioTypes } from '~/models/PortfolioType';
import { accountRoutes, baseRoutes } from '~/routes/applicationRoutes';
import { whiskeyRoutes } from '~/routes/whiskey';
import { splitPathArray } from '~/utils/routesHelper';
import { Box, Flex, Placeholder, Text } from '~/vinovest-design-system/components';
import { Button, ButtonAccount } from '~/vinovest-design-system/components/Button';
import {
    IconArrow,
    IconAvatar,
    IconBackHome,
    IconClose,
    IconHamburger,
    IconHome,
    IconMoney
} from '~/vinovest-design-system/components/Icons';
import { MenuLink, MenuLinks } from '~/vinovest-design-system/components/Navigation';

import { GlobalNavigation } from './GlobalNavigation';
import { ManagedNavigation } from './ManagedNavigation';
import { MarketplaceNavigation } from './MarketplaceNavigation';
import {
    NAVIGATION_DASHBOARD_LINKS,
    NAVIGATION_MANAGED_LINKS,
    NAVIGATION_TRADE_LINKS,
    NAVIGATION_WHISKEY_LINKS
} from './Navigation.data';
import { WhiskeyNavigation } from './WhiskeyNavigation';
import { ReferralGlobalModal } from '../Modal/ReferralGlobalModal';

/** Mobile to desktop responsive navigation for authenticated users. */
export const ApplicationNavigation = () => {
    const { isDesktop } = useWindowSize();
    const router = useRouter();
    const paths = splitPathArray(router?.asPath, router?.pathname);
    const { backTo, pageTitle } = useCustomPageTitle();
    const { firstName } = useAuthentication();
    const { setDestinationPortfolio, setDepositAmount } = useContext(DepositContext);
    const [toggleMenu, setToggleMenu] = useState(false);
    const dashboard = useMemo(() => {
        const paths = [baseRoutes.dashboard.href];
        const pathsRegex = new RegExp(`(${paths.join('|')})`);
        return pathsRegex.test(router.pathname);
        // router.pathname.includes(), [router.pathname]
    }, [router.pathname]);
    const trading = useMemo(() => router.pathname.includes(baseRoutes.trade.href), [router.pathname]);
    const managed = useMemo(() => router.pathname.includes(baseRoutes.managed.href), [router.pathname]);
    const whiskey = useMemo(() => router.pathname.includes(baseRoutes.whiskey.href), [router.pathname]);
    const whiskeyReserve = useMemo(() => router.pathname.includes(whiskeyRoutes.reserve.href), [router.pathname]);
    const whiskeyGuide = useMemo(() => router.pathname.includes(whiskeyRoutes.guide.href), [router.pathname]);
    const wineOffer = useMemo(() => router.pathname.startsWith('/managed/offer/'), [router.pathname]);
    const { data: managedPortfolio, isSuccess } = useManagedPortfolioQuery();
    const [isManaged, setIsManaged] = useState(false);
    const minWineDeposit = useMemo(() => {
        if (isSuccess && managedPortfolio && managedPortfolio.cash && managedPortfolio.positionTotal) {
            const cashAmount = typeof managedPortfolio.cash.amount === 'number' ? managedPortfolio.cash.amount : 0;
            const positionTotalAmount =
                typeof managedPortfolio.positionTotal.amount === 'number' ? managedPortfolio.positionTotal.amount : 0;
            return cashAmount + positionTotalAmount <= 100_000;
        }
        return false;
    }, [isSuccess, managedPortfolio]);

    const portfolio = useMemo(() => {
        if (trading) {
            return PortfolioTypes.trading;
        }
        if (whiskey) {
            return PortfolioTypes.whiskey;
        }
        setIsManaged(true);
        return PortfolioTypes.managed;
    }, []);

    const global = useMemo(
        () =>
            router.pathname.includes(baseRoutes.dashboard.href) ||
            router.pathname === baseRoutes.faq.href ||
            router.pathname === baseRoutes.invite.href ||
            router.pathname === baseRoutes.account.href ||
            router.pathname === accountRoutes.autoInvest.href ||
            router.pathname === accountRoutes.email.href ||
            router.pathname === accountRoutes.investmentPlan.href ||
            router.pathname === accountRoutes.password.href ||
            router.pathname === accountRoutes.phone.href ||
            router.pathname === accountRoutes.sell.href ||
            router.pathname === accountRoutes.liquidate.href ||
            router.pathname === baseRoutes.scheduledWines.href ||
            router.pathname.startsWith(baseRoutes.upsell.href),
        [router.pathname]
    );

    // deposit pages have no header 'add funds'
    const deposit = useMemo(() => router.pathname.startsWith(baseRoutes.upsell.href), [router.pathname]);

    const managedPortfolioContext = useContext(ManagedPortfolioContext);
    const tradingPortfolioContext = useContext(TradingPortfolioContext);
    const needsOnboardingTour = managedPortfolioContext?.needsOnboarding || tradingPortfolioContext?.needsOnboarding;
    const menuLinkData = trading
        ? NAVIGATION_TRADE_LINKS
        : managed
          ? NAVIGATION_MANAGED_LINKS
          : whiskey
            ? NAVIGATION_WHISKEY_LINKS
            : NAVIGATION_DASHBOARD_LINKS;

    const handleAddFunds = () => {
        switch (portfolio) {
            case PortfolioTypes.managed: {
                setIsManaged(true);
                setDestinationPortfolio(PortfolioTypes.managed);
                setDepositAmount(0);
                break;
            }
            case PortfolioTypes.whiskey: {
                setIsManaged(false);
                setDestinationPortfolio(PortfolioTypes.whiskey);
                setDepositAmount(0);
                break;
            }
            case PortfolioTypes.trading: {
                setIsManaged(false);
                setDestinationPortfolio(PortfolioTypes.trading);
                setDepositAmount(0);
                break;
            }
            default: {
                // Assumes default type is PortfolioTypes.managed
                setDestinationPortfolio(PortfolioTypes.managed);
                setDepositAmount(0);
                break;
            }
        }

        UrlModalHelper.openModal(
            router,
            DepositTransferModalKey,
            wineOffer ? baseRoutes.managed.href : minWineDeposit && isManaged ? '/managed/offer/wine-offer' : ''
        );
    };

    const AddFundsButton = () => {
        if (deposit) {
            return null;
        }
        return (
            <TrackEvent eventName={AnalyticsEventNames.AddFunds} metaData={{ initiator: AddFundsInitiators.Header }}>
                <Button
                    variant={'custom'}
                    className={
                        'text-11 font-mono rounded bg-green-600 text-white p-3 uppercase hover:bg-opacity-90 whitespace-nowrap'
                    }
                    onClick={handleAddFunds}
                >
                    Add Funds
                </Button>
            </TrackEvent>
        );
    };

    const logoType = whiskey ? 'light' : 'standard';

    return (
        <>
            <a href={'#main-content'} className={'skip-nav'}>
                Skip to main content
            </a>
            <Disclosure
                as={'nav'}
                id={'Header'}
                key={'ApplicationNavigation'}
                style={{ position: '-webkit-sticky', top: 0, zIndex: 20 }}
                className={`print:hidden sticky flex flex-col px-2 md:px-4 shadow-sm md:shadow-none ${
                    trading
                        ? 'bg-trading'
                        : managed
                          ? 'bg-turtleGreen-200'
                          : whiskey
                            ? 'bg-turtleGreen-800'
                            : 'bg-white'
                }`}
            >
                {({ open }) => (
                    <div className={'flex h-[75px] items-center'}>
                        <Box
                            classnames={`${
                                isDesktop || (!isDesktop && dashboard) ? 'block w-[75px] mr-[78px]' : 'hidden'
                            } `}
                        >
                            <Logo type={logoType} />
                        </Box>

                        <Flex classnames={'w-full flex-grow'}>
                            <Flex classnames={'flex-1'}>
                                <Placeholder renderCondition={paths[0]?.href !== '' && !dashboard} placeholder={null}>
                                    <Flex classnames={'items-center'}>
                                        <Link
                                            href={backTo}
                                            className={`relative items-center h-9 w-9 rounded-full justify-center inline-flex bg-white ${
                                                whiskey
                                                    ? 'bg-brown-300'
                                                    : trading || managed || whiskey
                                                      ? 'bg-white'
                                                      : 'bg-neutrals-200'
                                            }`}
                                        >
                                            <span className={'sr-only'}>Go back to {pageTitle}</span>
                                            {backTo === '/dashboard' && !whiskey ? (
                                                <IconBackHome width={'16px'} height={'17px'} />
                                            ) : (
                                                <IconArrow
                                                    width={'15px'}
                                                    height={'10px'}
                                                    stroke={'#242E35'}
                                                    className={'transform rotate-180'}
                                                />
                                            )}
                                        </Link>
                                        <Placeholder
                                            renderCondition={pageTitle !== '' && !dashboard}
                                            placeholder={null}
                                        >
                                            <Text
                                                classnames={`md:border-l md:border-black ml-4 md:ml-8 md:pl-8 capitalize whitespace-nowrap max-w-72 w-[180px] sm:w-[auto] truncate ${
                                                    whiskey ? '!text-brown-300 md:border-brown-300' : ''
                                                }`}
                                            >
                                                {whiskeyReserve
                                                    ? 'Invest in Whiskey'
                                                    : whiskeyGuide
                                                      ? 'American Whiskey Guide'
                                                      : pageTitle.replace('-', ' ')}
                                            </Text>
                                        </Placeholder>
                                    </Flex>
                                </Placeholder>
                                <Placeholder renderCondition={dashboard} placeholder={null}>
                                    <Flex classnames={'hidden md:flex items-center'}>
                                        {firstName && <Text classnames={'capitalize'}>{`Welcome ${firstName}!`}</Text>}
                                    </Flex>
                                </Placeholder>
                                <Placeholder renderCondition={global} placeholder={null}>
                                    <Flex
                                        classnames={'hidden md:ml-6 md:flex md:items-center md:self-end md:flex-grow '}
                                    >
                                        <Flex
                                            classnames={
                                                ' xs:ml-6 xs:flex xs:space-x-8 flex flex-grow self-end justify-end'
                                            }
                                        >
                                            <GlobalNavigation />
                                        </Flex>
                                        <Box classnames={'ml-8'}>
                                            {dashboard ? (
                                                <OnboardingTourTargetContainer
                                                    needsOnboarding={needsOnboardingTour}
                                                    stepNumber={0}
                                                    baseTargetSelector={
                                                        DASHBOARD_ONBOARDING_TOUR_STEP_ONE_BASE_SELECTOR
                                                    }
                                                    containerClassNames={'w-min'}
                                                    maximumAllowedScreenSize={'desktop'}
                                                >
                                                    <AddFundsButton />
                                                </OnboardingTourTargetContainer>
                                            ) : (
                                                <AddFundsButton />
                                            )}
                                        </Box>
                                    </Flex>
                                </Placeholder>
                                <Placeholder renderCondition={whiskey} placeholder={null}>
                                    <Flex
                                        classnames={'hidden md:ml-6 md:flex md:items-center md:self-end md:flex-grow '}
                                    >
                                        <Flex
                                            classnames={
                                                ' xs:ml-6 xs:flex xs:space-x-8 flex flex-grow self-end justify-end'
                                            }
                                        >
                                            <WhiskeyNavigation />
                                        </Flex>
                                        <Box classnames={'ml-8'}>
                                            {dashboard ? (
                                                <OnboardingTourTargetContainer
                                                    needsOnboarding={needsOnboardingTour}
                                                    stepNumber={0}
                                                    baseTargetSelector={
                                                        DASHBOARD_ONBOARDING_TOUR_STEP_ONE_BASE_SELECTOR
                                                    }
                                                    containerClassNames={'w-min'}
                                                    maximumAllowedScreenSize={'desktop'}
                                                >
                                                    <AddFundsButton />
                                                </OnboardingTourTargetContainer>
                                            ) : (
                                                <AddFundsButton />
                                            )}
                                        </Box>
                                    </Flex>
                                </Placeholder>
                                <Placeholder renderCondition={!global && !whiskey} placeholder={null}>
                                    <Flex
                                        classnames={
                                            'hidden md:ml-6 md:flex md:items-center md:flex-grow md:justify-end  '
                                        }
                                    >
                                        <Flex
                                            classnames={
                                                'hidden sm:ml-6 sm:flex sm:space-x-8  md:flex md:items-center md:content-middle'
                                            }
                                        >
                                            <MarketplaceNavigation />
                                            <ManagedNavigation />
                                        </Flex>
                                        <Box classnames={'ml-8'}>
                                            <AddFundsButton />
                                        </Box>
                                    </Flex>
                                </Placeholder>
                                <Flex classnames={'justify-end w-full items-center md:hidden'}>
                                    <Box>
                                        <OnboardingTourTargetContainer
                                            needsOnboarding={needsOnboardingTour}
                                            stepNumber={0}
                                            baseTargetSelector={DASHBOARD_ONBOARDING_TOUR_STEP_ONE_BASE_SELECTOR}
                                            containerClassNames={'w-min'}
                                            maximumAllowedScreenSize={'tablet'}
                                        >
                                            <AddFundsButton />
                                        </OnboardingTourTargetContainer>
                                    </Box>
                                    <Disclosure.Button
                                        className={
                                            'ml-3 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-neutrals-800'
                                        }
                                    >
                                        <span className={'sr-only'}>Open main menu</span>
                                        <IconHamburger
                                            height={'12px'}
                                            width={'18px'}
                                            stroke={whiskey ? '#FAE8D1' : '#242E35'}
                                        />
                                    </Disclosure.Button>
                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex classnames={'items-center justify-end w-[75px] ml-4 hidden md:block md:ml-[36px]'}>
                            <Flex classnames={'w-full gap-6 items-center self-end'}>
                                <Box classnames={'hidden md:block'}>
                                    <ButtonAccount color={whiskey ? '#FAE8D1' : null} />
                                </Box>
                            </Flex>
                        </Flex>

                        {/* Mobile Menu */}
                        <Transition.Root key={'MobileMenuTransitionRoot'} show={open} as={Fragment}>
                            <Dialog
                                as={'div'}
                                className={'fixed inset-0 overflow-hidden z-20'}
                                onClose={() => setToggleMenu(!toggleMenu)}
                            >
                                <Disclosure.Button
                                    className={
                                        'fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity w-full h-full'
                                    }
                                />

                                <div className={'pointer-events-none fixed inset-y-0 right-0 flex pl-10 max-w-[95%]'}>
                                    <Transition.Child
                                        as={Fragment}
                                        enter={'transform transition ease-in-out duration-500 sm:duration-700'}
                                        enterFrom={'translate-x-full'}
                                        enterTo={'translate-x-0'}
                                        leave={'transform transition ease-in-out duration-500 sm:duration-700'}
                                        leaveFrom={'translate-x-0'}
                                        leaveTo={'translate-x-full'}
                                    >
                                        <div className={'pointer-events-auto w-screen max-w-md'}>
                                            <div
                                                className={'flex h-full flex-col overflow-y-scroll bg-white shadow-xl'}
                                            >
                                                <div
                                                    className={
                                                        'relative pt-2 pb-3 flex flex-col text-20 px-6 border-b border-neutrals-200'
                                                    }
                                                >
                                                    <div className={'block absolute top-5 right-5'}>
                                                        <Disclosure.Button
                                                            className={
                                                                'w-8 h-8 bg-neutrals-200 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 p-3'
                                                            }
                                                        >
                                                            <span className={'sr-only'}>Close main menu</span>
                                                            <IconClose
                                                                height={'12px'}
                                                                width={'12px'}
                                                                stroke={'#242E35'}
                                                                className={'ml-[-2px] mt-[-2px]'}
                                                            />
                                                        </Disclosure.Button>
                                                    </div>

                                                    {dashboard && firstName ? (
                                                        <Text
                                                            classnames={'capitalize mt-5 mb-8 text-16'}
                                                            color={'text-gray-500'}
                                                        >
                                                            Welcome, {firstName}!
                                                        </Text>
                                                    ) : (
                                                        <Placeholder
                                                            renderCondition={pageTitle !== ''}
                                                            placeholder={null}
                                                        >
                                                            <Text classnames={'text-16 my-4 text-gray-500 capitalize'}>
                                                                {pageTitle.replaceAll('-', ' ')}
                                                            </Text>
                                                        </Placeholder>
                                                    )}

                                                    <MenuLinks classnames={'flex-col w-full pt-3'}>
                                                        {menuLinkData.map((link) =>
                                                            link.label === 'Invite' ? (
                                                                <MenuLink
                                                                    key={link.label}
                                                                    classnames={'text-20 py-5'}
                                                                    onClick={() => {
                                                                        UrlModalHelper.openModal(
                                                                            router,
                                                                            ReferralGlobal
                                                                        );
                                                                    }}
                                                                >
                                                                    {link.label}
                                                                </MenuLink>
                                                            ) : (
                                                                <MenuLink
                                                                    key={link.label}
                                                                    classnames={'text-20 py-5'}
                                                                    link={link.to}
                                                                >
                                                                    {link.label}
                                                                </MenuLink>
                                                            )
                                                        )}
                                                    </MenuLinks>
                                                </div>
                                                <div className={'mt-4 px-6'}>
                                                    <MenuLinks classnames={'flex-col w-full pt-3'}>
                                                        <MenuLink
                                                            onClick={handleAddFunds}
                                                            classnames={'text-20 py-5 text-green-600'}
                                                            variant={'custom'}
                                                        >
                                                            <span
                                                                className={
                                                                    'w-[38px] h-[38px] bg-neutrals-200 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 pt-2 mr-3 text-center'
                                                                }
                                                            >
                                                                <IconMoney className={'h-[18px] w-[23px] m-auto'} />
                                                            </span>
                                                            Add Funds
                                                        </MenuLink>
                                                        <MenuLink
                                                            link={baseRoutes.dashboard.href}
                                                            classnames={'text-20 py-5'}
                                                            variant={'custom'}
                                                        >
                                                            <span
                                                                className={
                                                                    'w-[38px] h-[38px] bg-neutrals-200 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 pt-2 mr-3 text-center'
                                                                }
                                                            >
                                                                <IconHome className={'h-[18px] w-[19px] m-auto'} />
                                                            </span>
                                                            Dashboard
                                                        </MenuLink>
                                                        <MenuLink
                                                            link={baseRoutes.account.href}
                                                            classnames={'text-20 py-5'}
                                                            variant={'custom'}
                                                        >
                                                            <span
                                                                className={
                                                                    'w-[38px] h-[38px] bg-neutrals-200 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 pt-2 mr-3 text-center'
                                                                }
                                                            >
                                                                <IconAvatar className={'h-[18px] w-[19px] m-auto'} />
                                                            </span>
                                                            Account
                                                        </MenuLink>
                                                    </MenuLinks>
                                                </div>
                                            </div>
                                        </div>
                                    </Transition.Child>
                                </div>
                            </Dialog>
                        </Transition.Root>

                        <ReferralGlobalModal />
                    </div>
                )}
            </Disclosure>
        </>
    );
};
