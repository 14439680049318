import React from 'react';
import classNames from 'classnames';

import { ClassNamesProps } from '~/models/ClassNamesProps';

export const MenuLinks: React.FC<ClassNamesProps> = ({ children, classnames }) => {
    return <ul className={classNames(MenuLinksBase, [classnames])}>{children}</ul>;
};

export const MenuLinksBase = 'flex';
