import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';

import { FooterTitle } from './Footer.styled';

export const data = [
    {
        title: 'Champagne (Taste, Best Bottles, Prices 2021)',
        link: '/blog/champagne'
    },
    {
        title: 'How to Make a Mimosa (Recipe, Wine Ideas, Serving)',
        link: '/blog/mimosa'
    },
    {
        title: 'Easy Sangria Recipes (Tips, Best Wines)',
        link: '/blog/sangria'
    },
    {
        title: 'Malbec Wine - Winemaking, Best Wines, Prices (2021)',
        link: '/blog/malbec-wine'
    },
    {
        title: 'Sauvignon Blanc (Best Wines, Taste, Prices 2021)',
        link: '/blog/sauvignon-blanc'
    }
];

const FooterRecentArticles: React.FC = () => {
    return (
        <RecentArticlesWrapper>
            <FooterTitle className={'text-brown-300'}>Recent articles</FooterTitle>
            {data.map(({ title, link }) => (
                <Link key={title} href={link} passHref legacyBehavior>
                    <RecentArticleLink>{title}</RecentArticleLink>
                </Link>
            ))}
        </RecentArticlesWrapper>
    );
};

const RecentArticlesWrapper = styled.div`
    padding-top: 28px;
    padding-bottom: 39px;

    ${FooterTitle} {
        padding-bottom: 9px;
    }

    @media screen and (max-width: 1024px) {
        padding-top: 29px;
        padding-right: 40px;
    }

    @media screen and (max-width: 767px) {
        padding-top: 31px;
        padding-bottom: 42px;
        border-bottom: 1px solid #fae8d1;

        ${FooterTitle} {
            padding-bottom: 11px;
        }
    }
`;

const RecentArticleLink = styled.a`
    text-decoration: none;
    color: inherit;
    font-family: var(--font-vinovest-medium);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    margin-top: 6px;
    display: block;
    transition: opacity 0.3s;

    &:hover {
        opacity: 0.8;
    }

    @media screen and (max-width: 1024px) {
        margin-top: 20px;
    }

    @media screen and (max-width: 767px) {
        font-size: 12px;
        line-height: 20px;
        margin-top: 17px;
    }
    @media screen and (min-width: 375px) {
        font-size: 16px;
    }

    @media screen and (min-width: 320px) {
        font-size: 15px;
    }
`;

export default FooterRecentArticles;
