import { FC, PropsWithChildren } from 'react';

import { Flex } from '~/vinovest-design-system/components';

interface ModalFooterProps {
    footerClassNames?: string;
}

export const ModalFooter: FC<PropsWithChildren<ModalFooterProps>> = ({
    footerClassNames,
    children
}: PropsWithChildren<ModalFooterProps>) => <Flex classnames={footerClassNames}>{children}</Flex>;
