import { FC, PropsWithChildren } from 'react';
import ReactModal, { Props } from 'react-modal';
import classNames from 'classnames';

export const DEFAULT_MODAL_CLOSE_TIMEOUT = 300;
const DEFAULT_MIN_HEIGHT = '600px';
const DEFAULT_MAX_WIDTH = '590px';

interface ModalContainerProps extends Omit<Props, 'isOpen'> {
    /** Whether to show the modal */
    show: boolean;
    /** Callback invoked to handle when the modal is closed */
    handleClose: () => void;
    /** Optional - classNames string to apply to the modal element */
    modalClassNames?: string;
    /** Optional - classNames string to apply to the overlay element */
    overlayClassNames?: string;
    /** Optional - whether the modal should be closed when the "esc" key is pressed */
    shouldCloseOnEsc?: boolean;
    /** Optional - style object to apply to the modal. May be needed for styling which cannot be applied using classNames */
    modalStyles?: ReactModal.Styles;
    /** Optional - timeout period to use for the close animation */
    closeTimeout?: number;
}

/** Generic modal container component */
export const ModalContainer: FC<PropsWithChildren<ModalContainerProps>> = ({
    show,
    handleClose,
    modalClassNames,
    overlayClassNames,
    shouldCloseOnEsc,
    modalStyles,
    closeTimeout,
    children,
    ...modalProps
}: PropsWithChildren<ModalContainerProps>) => (
    <ReactModal
        style={{ content: { minHeight: DEFAULT_MIN_HEIGHT, maxWidth: DEFAULT_MAX_WIDTH }, ...modalStyles }}
        closeTimeoutMS={closeTimeout ?? DEFAULT_MODAL_CLOSE_TIMEOUT}
        className={classNames('bg-white px-4 py-6', modalClassNames)}
        ariaHideApp={false}
        shouldCloseOnEsc={shouldCloseOnEsc}
        shouldCloseOnOverlayClick={false}
        bodyOpenClassName={'relative overflow-hidden'}
        shouldFocusAfterRender={false}
        onRequestClose={handleClose}
        overlayClassName={classNames(
            'fixed flex inset-0 items-center justify-center bg-neutrals-400 z-100 bg-opacity-50',
            overlayClassNames
        )}
        {...modalProps}
        isOpen={show}
    >
        {children}
    </ReactModal>
);
