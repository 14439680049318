import React, { type ReactNode } from 'react';

/** Navigation that slides in from the top of the screen. */
export interface MenuPullDownProps {
    active: boolean;
    children?: ReactNode;
}

export const MenuPullDown: React.FC<MenuPullDownProps> = ({ children, active }) => {
    const height = active ? '' : '-translate-y-full';

    return <div className={`${MenuPullDownBase} ${height}`}>{children}</div>;
};

const MenuPullDownBase =
    'bg-platinum-800 duration-500 ease-in-out fixed justify-between overflow-auto bottom-0 top-0 transition-transform transform z-40 w-full items-start shadow';
