import { createRoute } from 'next-typed-routes';

/**
 * Nested trade routes
 */
export enum TradeRoutes {
    Activity = '/trade/activity',
    PortfolioDetail = '/trade/portfolio/[trading-detail]',
    Setup = '/trade/setup',
    Portfolio = '/trade/portfolio',
    Collections = '/trade/collections',
    DirectoryCountry = '/trade/[country]',
    DirectoryRegion = '/trade/[country]/[region]',
    Marketplace = '/trade/marketplace',
    SingleCollection = '/trade/collections/[collection]',
    CollectionVintage = '/trade/collections/[collection]/[wine]/[vintage]',
    CollectionWine = '/trade/collections/[collection]/[wine]',
    WineDirectory = '/trade'
}

/** Trade routes for use with next router */
export const tradeRoutes = {
    activity: createRoute(TradeRoutes.Activity),
    collections: createRoute(TradeRoutes.Collections),
    marketplace: createRoute(TradeRoutes.Marketplace),
    wineDirectory: createRoute(TradeRoutes.WineDirectory),
    trade: createRoute(TradeRoutes.Collections),
    tradeSetup: createRoute(TradeRoutes.Setup),
    tradingPortfolio: createRoute(TradeRoutes.Portfolio),
    portfolioDetail: createRoute(TradeRoutes.PortfolioDetail),
    vintage: (collectionRoute: any, wineRoute: any, vintageRoute: any) =>
        createRoute(TradeRoutes.CollectionVintage, {
            collection: collectionRoute,
            wine: wineRoute,
            vintage: vintageRoute
        }),
    wine: (collectionRoute: any, wineRoute: any) =>
        createRoute(TradeRoutes.CollectionWine, {
            collection: collectionRoute,
            wine: wineRoute
        })
};
