import { useContext } from 'react';
import { QueryClient, useQuery } from 'react-query';

import { queries } from '~/constants/queryKeys';
import { TradingPortfolioContext } from '~/context/TradingPortfolioContext';
import {
    VinovestNetworkHelperAuthenticationParams,
    VinovestNetworkingHelper
} from '~/helpers/VinovestNetworkingHelper';
import { useAuthentication } from '~/hooks/useAuthentication';
import { GetTradingPortfolioBottlesResponse } from '~/networking';

export const TradingPortfolioBottlesQuery = {
    load: async (
        authenticationParams: VinovestNetworkHelperAuthenticationParams
    ): Promise<GetTradingPortfolioBottlesResponse | null> => {
        const client = VinovestNetworkingHelper.getClient(authenticationParams);
        const { data: trading } = await client.trading.getTradingPortfolioBottles();
        if (trading) {
            return trading;
        }
        return null;
    },
    queryKey: (userId: string) => [queries.getTradingPortfolioBottles, userId],
    prefetch: async (queryClient: QueryClient, authenticationParams: VinovestNetworkHelperAuthenticationParams) => {
        const { userId } = authenticationParams;
        const queryKey = TradingPortfolioBottlesQuery.queryKey(userId || '');
        await queryClient.prefetchQuery(queryKey, async () => TradingPortfolioBottlesQuery.load(authenticationParams));
    }
};
/** Query for fetching users trading portfolio bottles */
export const useTradingPortfolioBottles = () => {
    const { isAuthenticated } = useAuthentication();
    const { id } = useContext(TradingPortfolioContext);

    return useQuery(
        [queries.getTradingPortfolioBottles, id],
        async (): Promise<GetTradingPortfolioBottlesResponse | undefined> => {
            const client = VinovestNetworkingHelper.getClient();
            const { data: tradingBottles } = await client.trading.getTradingPortfolioBottles();
            if (tradingBottles) {
                return tradingBottles;
            }
            return undefined;
        },
        {
            enabled: Boolean(isAuthenticated && id)
        }
    );
};
