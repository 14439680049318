import { MouseEvent } from 'react';

import { Button } from '~/vinovest-design-system/components';

interface OnboardingTourTooltipPrimaryButtonProps {
    text: string;
    onClick: (e: MouseEvent) => void;
}
/** Primary button for an onboarding tooltip step */
export const OnboardingTourTooltipPrimaryButton: React.FC<OnboardingTourTooltipPrimaryButtonProps> = ({
    text,
    onClick
}: OnboardingTourTooltipPrimaryButtonProps) => {
    return (
        <Button
            variant={'custom'}
            className={'py-1 px-5 h-10 bg-brown-600 rounded text-white text-14 hover:bg-opacity-90'}
            onClick={onClick}
        >
            {text}
        </Button>
    );
};
