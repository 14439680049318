import { useSavedPaymentMethod } from './useSavedPaymentMethod';
import { useGetUserPortfoliosInfo } from '../queries/useGetUserPortfoliosInfo';

/** Returns user's saved bank and credit card accounts. */
export const useAllSavedPaymentMethods = () => {
    const { whiskey, trading, managed } = useGetUserPortfoliosInfo();

    const { data: managedPayments, isSuccess: managedSuccess } = useSavedPaymentMethod(managed?.id);
    const { data: tradingPayments, isSuccess: tradingSuccess } = useSavedPaymentMethod(trading?.id);
    const { data: whiskeyPayments, isSuccess: whiskeySucess } = useSavedPaymentMethod(whiskey?.id);

    return {
        paymentMethods: [
            ...(managedPayments?.paymentMethods || []),
            ...(tradingPayments?.paymentMethods || []),
            ...(whiskeyPayments?.paymentMethods || [])
        ],
        isSuccess: managedSuccess && tradingSuccess && whiskeySucess
    };
};
