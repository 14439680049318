import { useRouter } from 'next/router';

import { AnalyticsEventNames } from '~/constants/AnalyticsEventMap';
import { ReferralGlobal, UrlModalHelper } from '~/helpers/UrlModalHelper';
import { baseRoutes, managedRoutes, tradeRoutes } from '~/routes/applicationRoutes';
import { whiskeyRoutes } from '~/routes/whiskey';
import { MenuLink } from '~/vinovest-design-system/components/Navigation';

export const GlobalNavigation = () => {
    const router = useRouter();

    return (
        <>
            <MenuLink
                classnames={'text-20 md:text-16 py-5 px-[16px] items-center !ml-0'}
                link={managedRoutes.overview.href}
            >
                Wine
            </MenuLink>
            <MenuLink
                classnames={'text-20 md:text-16 py-5 px-[16px] items-center !ml-0'}
                link={whiskeyRoutes.reserve.href}
            >
                Whiskey
            </MenuLink>
            <MenuLink
                classnames={'text-20 md:text-16 py-5 px-[16px] items-center !ml-0'}
                link={tradeRoutes.marketplace.href}
            >
                Trading
            </MenuLink>
            <MenuLink
                track={{
                    eventName: AnalyticsEventNames.ReferralAction,
                    metaData: { action: 'navigate to invite' }
                }}
                classnames={'text-20 md:text-16 py-5 px-[16px] items-center !ml-0'}
                onClick={() => {
                    UrlModalHelper.openModal(router, ReferralGlobal);
                }}
            >
                Invite
            </MenuLink>
            <MenuLink classnames={'text-20 md:text-16 py-5 px-[16px] items-center !ml-0'} link={baseRoutes.help.href}>
                FAQ
            </MenuLink>
        </>
    );
};
