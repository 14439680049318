import React, { PropsWithChildren } from 'react';
import { useAuthentication } from '~/hooks/useAuthentication';
import PrivateAppContainer, { type AppContainerProps } from './PrivateAppContainer';
import dynamic from 'next/dynamic';
import { PageLoad } from '~/components/modules/PageLoad/PageLoad';
import { protectedRoutesRegex } from '~/server/constants';
import { useRouter } from 'next/router';

const DynamicContainer = dynamic(() => import('./PrivateAppContainer'), {
    loading: () => <PageLoad ready={false} />,
    ssr: false
});

const AppContainer: React.FC<PropsWithChildren<AppContainerProps>> = (props) => {
    const { isAuthenticated } = useAuthentication();
    const router = useRouter();

    if (isAuthenticated || protectedRoutesRegex.test(router.pathname)) {
        // never allow authenticated content to be server generated
        return <DynamicContainer {...props} />;
    }

    return <PrivateAppContainer {...props} />;
};

export default AppContainer;
