import React, { type SVGProps } from 'react';
import Link from 'next/link';

import { baseRoutes } from '~/routes/base';
import { useAuthentication } from '~/hooks/useAuthentication';

type LogoTypes = 'standard' | 'green' | 'brown' | 'light';

interface LogoProps extends SVGProps<SVGSVGElement> {
    type: LogoTypes;
    linkProps?: React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;
}

interface LogoColors {
    rect: string;
    fill: string;
}

const data: Record<LogoTypes, LogoColors> = {
    standard: { rect: '#242E35', fill: '#FAE8D1' },
    green: { rect: '#3C400C', fill: '#E0E5CD' },
    brown: { rect: '#513011', fill: '#E0E5CD' },
    light: { rect: '#EFDDC7', fill: '#242E35' }
};

interface IconProps extends SVGProps<SVGSVGElement> {
    type: LogoTypes;
}

export const LogoIcon: React.FC<IconProps> = ({ type = 'standard', width = '75', height = '75', ...rest }) => {
    const { rect, fill } = data[type];

    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 90 90`}
            fill={'none'}
            {...rest}
            aria-label={'Vinovest Logo'}
            xmlns={'http://www.w3.org/2000/svg'}
        >
            <rect width={'90'} height={'90'} fill={rect} />
            <path
                d={
                    'M70.7859 57.2611C70.7859 58.1461 70.063 58.8701 69.1793 58.8701C68.2956 58.8701 67.5727 58.1461 67.5727 57.2611V50.3422H70.7859V47.124H67.5727V42.2969H64.3594V47.124V50.3422V57.1806C64.3594 59.8356 66.5283 62.0078 69.1793 62.0078C71.8303 62.0078 73.9992 59.8356 73.9992 57.1806H70.7859V57.2611Z'
                }
                fill={fill}
            />
            <path
                d={
                    'M41.786 46.7217C39.6974 46.7217 37.6891 47.5262 36.2431 49.0548C34.7971 50.5834 34.0741 52.5947 34.1545 54.6865C34.3151 58.7091 37.7694 62.0077 41.786 62.0077C43.7943 62.0077 45.7223 61.2032 47.1683 59.755L44.919 57.5024C44.1157 58.3069 42.991 58.7896 41.786 58.7896C39.9384 58.7896 38.3317 57.5828 37.6891 55.9737H49.4979V54.3647C49.4176 50.1811 45.9633 46.7217 41.786 46.7217ZM37.6088 52.8361C37.8497 52.2729 38.0907 51.7902 38.5727 51.3879C39.4564 50.503 40.581 50.0202 41.786 50.0202C43.6337 50.0202 45.2403 51.227 45.883 52.8361H37.6088Z'
                }
                fill={fill}
            />
            <path d={'M38.5727 28.7695H35.3594V43.3315H38.5727V28.7695Z'} fill={fill} />
            <path
                d={
                    'M25.8809 39.7111L21.6233 28.7695H18.0083H16V31.9876H19.2936L23.6316 43.3315H24.4349H27.2465H28.1302L33.7534 28.7695H30.1385L25.8809 39.7111Z'
                }
                fill={fill}
            />
            <path
                d={
                    'M25.8005 58.0656L21.6233 47.124H18.0083H16V50.3421H19.2133L23.6316 61.686H24.4349H27.2465H28.0498L33.6731 47.124H30.0581L25.8005 58.0656Z'
                }
                fill={fill}
            />
            <path d={'M38.5727 22.333H35.3594V25.5511H38.5727V22.333Z'} fill={fill} />
            <path
                d={
                    'M66.3679 28.3672C62.1103 28.3672 58.7363 31.8267 58.7363 36.0102C58.7363 40.1937 62.1906 43.6532 66.3679 43.6532C70.5452 43.6532 73.9994 40.1937 73.9994 36.0102C73.9994 31.8267 70.6255 28.3672 66.3679 28.3672ZM66.3679 40.5156C63.9579 40.5156 61.9496 38.5042 61.9496 36.0907C61.9496 33.6771 63.9579 31.6658 66.3679 31.6658C68.7778 31.6658 70.7861 33.6771 70.7861 36.0907C70.7861 38.5042 68.8582 40.5156 66.3679 40.5156Z'
                }
                fill={fill}
            />
            <path
                d={
                    'M57.2112 52.9973C56.6489 52.8364 56.0866 52.6755 55.7652 52.4341C55.4439 52.2732 54.8816 51.9514 54.7209 51.4687C54.5603 50.9055 54.8816 50.3424 55.3636 50.0205C56.4079 49.4574 58.4162 50.1814 59.3802 51.2273L61.6295 48.9747C59.7015 47.0438 56.3276 45.9175 53.8373 47.2047C51.9093 48.2506 50.9453 50.5033 51.6683 52.5146C52.07 53.7214 53.0339 54.6868 54.3193 55.3304C54.8816 55.6522 55.6046 55.8131 56.2472 56.0545C57.2915 56.3763 58.4162 56.7786 58.7375 57.2613C58.8179 57.4222 58.8179 57.5831 58.8179 57.744C58.7375 58.0658 58.4162 58.3876 58.0145 58.5485C57.4522 58.7094 56.8899 58.8703 56.1669 58.7899C55.0423 58.7899 53.9176 58.3072 53.1946 57.5026L50.9453 59.7553C52.311 61.123 54.2389 61.9276 56.1669 62.008C56.2472 62.008 56.3276 62.008 56.4882 62.008C57.5325 62.008 58.4162 61.8471 59.2998 61.5253C60.5852 61.0426 61.6295 59.9967 62.0311 58.7899C62.4328 57.6636 62.2721 56.5372 61.6295 55.5718C60.5048 54.0432 58.6572 53.48 57.2112 52.9973Z'
                }
                fill={fill}
            />
            <path
                d={
                    'M49.0163 28.3672C47.3293 28.3672 45.803 28.9304 44.598 29.8153V28.7695H41.3848V36.0102V43.251H44.598V36.0102C44.598 33.5966 46.6064 31.5853 49.0163 31.5853C51.4263 31.5853 53.4346 33.5966 53.4346 36.0102V43.251H56.6479V36.0102C56.7282 31.8267 53.2739 28.3672 49.0163 28.3672Z'
                }
                fill={fill}
            />
        </svg>
    );
};

export const Logo: React.FC<LogoProps> = ({ type = 'standard', linkProps, ...imageProps }) => {
    const { isAuthenticated, isAuthLoading } = useAuthentication();

    if (!isAuthenticated || isAuthLoading) {
        return (
            <a href={'/'} className={'max-h-fit'} {...linkProps}>
                <LogoIcon type={type} {...imageProps} />
            </a>
        );
    }

    return (
        <Link href={baseRoutes.dashboard.href} className={'block h-[75px] w-[75px] relative'} {...linkProps}>
            <LogoIcon type={type} {...imageProps} />
        </Link>
    );
};

export default Logo;
