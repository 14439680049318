import React from 'react';

import { FooterContainerDark, GridContainer } from './Footer.styled';
import FooterDisclaimer from './FooterDisclaimer';
import FooterNavigation from './FooterNavigation';
import FooterNewsLetter from './FooterNewsLetter';
import FooterRecentArticles from './FooterRecentArticles';
import FooterStartInvestingBanner from './FooterStartInvestingBanner';
import FooterSupport from './FooterSupport';

interface Props {
    showFooterInvestingBanner: boolean;
}

const FooterDark: React.FC<Props> = (props) => {
    const { showFooterInvestingBanner } = props;
    return (
        <>
            {showFooterInvestingBanner && <FooterStartInvestingBanner />}
            <FooterContainerDark>
                <GridContainer>
                    <div className={'a border-pla'}>
                        <FooterNavigation />
                    </div>
                    <div className={'b'}>
                        <FooterRecentArticles />
                    </div>
                    <div className={'c'}>
                        <FooterNewsLetter />
                    </div>
                    <div className={'d'}>
                        <FooterSupport />
                    </div>
                </GridContainer>
                <FooterDisclaimer />
            </FooterContainerDark>
        </>
    );
};

export default FooterDark;
