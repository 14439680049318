import { FC } from 'react';

interface NavigationBadgeProps {
    label: string;
    classAddition?: string;
}

export const NavigationBadge: FC<NavigationBadgeProps> = ({ label, classAddition }) => {
    return (
        <span
            className={`bg-blue-600 leading-none ml-2 px-1 py-1 rounded-r rounded-tl text-[8px] text-white uppercase${
                ` ${classAddition}` || ''
            }`}
        >
            {label}
        </span>
    );
};
