import { useRouter } from 'next/router';

import { AnalyticsEventNames } from '~/constants/AnalyticsEventMap';
import { ReferralGlobal, UrlModalHelper } from '~/helpers/UrlModalHelper';
import { BaseRoutes, tradeRoutes } from '~/routes/applicationRoutes';
import { MenuLink } from '~/vinovest-design-system/components/Navigation';

import { showManagedNavigation } from './ManagedNavigation';

export const MarketplaceNavigation = () => {
    const router = useRouter();
    if (showManagedNavigation(router)) {
        return null;
    }

    return (
        <ul className={'list-none flex'} role={'navigation'}>
            <MenuLink
                classnames={'text-20 md:text-16 py-5 px-[16px] items-center !ml-0'}
                link={tradeRoutes.marketplace.href}
            >
                Marketplace
            </MenuLink>
            <MenuLink
                classnames={'text-20 md:text-16 py-5 px-[16px] items-center !ml-0'}
                link={tradeRoutes.collections.href}
            >
                Collections
            </MenuLink>
            <MenuLink
                track={{
                    eventName: AnalyticsEventNames.ViewTradingPortfolio,
                    metaData: { initiator: 'mobile_navigation' }
                }}
                classnames={'text-20 md:text-16 py-5 px-[16px] items-center !ml-0'}
                link={tradeRoutes.tradingPortfolio.href}
            >
                Portfolio
            </MenuLink>
            <MenuLink
                classnames={'text-20 md:text-16 py-5 px-[16px] items-center !ml-0'}
                link={tradeRoutes.activity.href}
            >
                Activity
            </MenuLink>
            <MenuLink
                track={{
                    eventName: AnalyticsEventNames.ReferralAction,
                    metaData: { action: 'navigate to invite' }
                }}
                classnames={'text-20 md:text-16 py-5 px-[16px] items-center !ml-0'}
                onClick={() => {
                    UrlModalHelper.openModal(router, ReferralGlobal);
                }}
            >
                Invite
            </MenuLink>
            <MenuLink classnames={'text-20 md:text-16 py-5 px-[16px] items-center !ml-0'} link={BaseRoutes.Help}>
                FAQ
            </MenuLink>
        </ul>
    );
};
