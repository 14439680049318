import React, { FC, PropsWithChildren } from 'react';

import { Button, Flex } from '~/vinovest-design-system/components';
import { IconCloseOutlined } from '~/vinovest-design-system/components/Icons';

interface ModalHeaderWithIconProps {
    showCloseIcon: boolean;
    onCloseIconClick: (e: React.MouseEvent<HTMLElement>) => void;
    headerClassNames?: string;
}

interface ModalHeaderWithOutIconProps {
    showCloseIcon: never;
    onCloseIconClick: never;
    headerClassNames?: string;
}

type ModalHeaderProps = ModalHeaderWithIconProps | ModalHeaderWithOutIconProps;

export const ModalHeader: FC<PropsWithChildren<ModalHeaderProps>> = ({
    headerClassNames,
    showCloseIcon,
    onCloseIconClick,
    children
}: PropsWithChildren<ModalHeaderProps>) => (
    <Flex classnames={headerClassNames}>
        {children}
        {showCloseIcon && (
            <Button variant={'custom'} className={'p-0'} onClick={onCloseIconClick}>
                <IconCloseOutlined height={'32px'} width={'32px'} stroke={'#242E35'} />
            </Button>
        )}
    </Flex>
);
