/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { NavigationBadge } from '~/components/modules/Badges/NavigationBadge';
import { Logo } from '~/components/modules/Logo/Logo';
import { PosthogHelper } from '~/helpers/PosthogHelper';
import { baseRoutes } from '~/routes/applicationRoutes';
import { AnalyticsEventNames } from '~/tracking';
import { Button, ButtonMenu } from '~/vinovest-design-system/components/Button';
import { IconClose } from '~/vinovest-design-system/components/Icons';
import { MenuLink, MenuLinks } from '~/vinovest-design-system/components/Navigation';

import { NAVIGATION_LINKS_GUEST } from './Navigation.data';
import { MenuPullDownDark } from '~/vinovest-design-system/components/Navigation/components/MenuPullDownDark';

export const MobileMarketingNavigationDark = () => {
    const [active, setActive] = useState(false);
    const [showCTA, setShowCTA] = useState(false);
    const toggleMenu = () => setActive(!active);
    const router = useRouter();
    const mobileNavMenuItems = NAVIGATION_LINKS_GUEST;
    const navBackgroundColor = 'bg-platinum-850';
    const logoType = 'light';
    const menuColor = '#efddc7';
    const handleScroll = () => {
        if (window.scrollY > 200) {
            setShowCTA(true);
        } else {
            setShowCTA(false);
        }
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    });

    return (
        <div className={`${navBackgroundColor} flex items-center justify-between relative w-full top-0 z-20`}>
            <ButtonMenu
                toggleMobileNav={() => setActive(!active)}
                color={menuColor}
                classnames={'absolute z-[2] left-3 top-6'}
            />

            <div className={'flex justify-center w-[100%]'}>
                <Logo type={logoType} />
            </div>

            <Button
                onClick={() => {
                    router.push(baseRoutes.signup.href);
                }}
                variant={'green'}
                padding={['px-2', 'py-2']}
                classnames={`mr-[10px] flex-1 max-w-[120px] z-[2] absolute right-[10px] transition ease-in-out duration-300 ${showCTA ? 'opacity-100' : 'opacity-0'}`}
            >
                Get Started
            </Button>
            <MenuPullDownDark active={active}>
                <div className={'flex justify-center w-full items-center'}>
                    <button
                        aria-label={'close'}
                        type={'button'}
                        onClick={toggleMenu}
                        className={'absolute left-6 top-6'}
                    >
                        <IconClose height={'20px'} width={'20px'} stroke={'#EFDDC7'} />
                    </button>
                    <Logo type={'light'} />
                </div>
                <div className={'flex text-center py-9 px-8 border-b'}>
                    <Button
                        onClick={() => {
                            PosthogHelper.captureEvent(AnalyticsEventNames.UserSelectLogin);
                            router.push(baseRoutes.login.href);
                        }}
                        variant={'quaternary'}
                        padding={['px-5', 'py-4']}
                        classnames={'flex-1 text-brown-400 border-brown-400'}
                    >
                        Log in
                    </Button>
                    <Button
                        onClick={() => {
                            router.push(baseRoutes.signup.href);
                        }}
                        variant={'green'}
                        padding={['px-5', 'py-4']}
                        classnames={'ml-12 flex-1'}
                    >
                        Get Started
                    </Button>
                </div>
                <MenuLinks classnames={'flex-col w-full'}>
                    {mobileNavMenuItems.map((item) => (
                        <MenuLink
                            key={item.label}
                            classnames={'text-17 py-7 uppercase border-b px-6 font-mono'}
                            fontClass={'text-brown-400'}
                            link={item.to}
                            onClick={() => setActive(!active)}
                        >
                            {item.label}
                            {item.label === 'WHISKEYVEST' && (
                                <NavigationBadge label={'New!'} classAddition={'-mt-10'} />
                            )}
                        </MenuLink>
                    ))}
                </MenuLinks>
            </MenuPullDownDark>
        </div>
    );
};
