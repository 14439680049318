/**
 * splitPathArray: Takes in a route string, converts to Array of titles and their root paths as relative urls.
 * @param linkPath - The route as string
 * @returns - Returns an Array of titles and their root paths as relative urls.
 */
export const splitPathArray = (linkPath: string, pathname: string) => {
    const splitLinkPath = linkPath.split('/').filter((path) => !path.substring(0, path.indexOf('#')));
    const splitPathName = pathname.split('/');
    splitLinkPath.shift();
    splitPathName.shift();
    const linkPathArray = splitLinkPath.map((path, i) => {
        return {
            title: path,
            href: `/${splitLinkPath?.slice(0, i + 1).join('/')}`,
            route: `/${splitPathName?.slice(0, i + 1).join('/')}`
        };
    });

    return linkPathArray;
};
