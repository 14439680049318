import React from 'react';

import FooterDark from '~/components/modules/Footer/FooterDark';

interface Props {
    showFooterInvestingBanner: boolean;
}

const FooterContainer: React.FC<Props> = (props) => {
    return <FooterDark {...props} />;
};

export default FooterContainer;
