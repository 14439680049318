import { Step, TooltipRenderProps } from 'react-joyride';

/** Props passed to every tour step component when rendered */
export interface OnboardingTourStepContainerSharedProps {
    primaryProps: TooltipRenderProps['primaryProps'];
    closeProps: TooltipRenderProps['closeProps'];
    skipProps: TooltipRenderProps['skipProps'];
}

/** Shared onboarding tour step options */
export const SHARED_STEP_OPTIONS: { placement: Step['placement']; disableBeacon: Step['disableBeacon'] } = {
    placement: 'bottom-end',
    disableBeacon: true
};
