import React from 'react';
import Image from 'next/legacy/image';

import GooglePlay from '~/assets/img/support/GooglePlay.svg';

export default function DownloadAndroidApp() {
    return (
        <>
            <a
                target={'_blank'}
                rel={'noopener noreferrer'}
                href={'https://play.google.com/store/apps/details?id=co.vinovest.portfolio'}
            >
                <Image src={GooglePlay as string} width={'160'} height={'48'} alt={'Google Play'} />
            </a>
        </>
    );
}
