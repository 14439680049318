import { useCallback, useEffect, useState } from 'react';
import { EmailShareButton, FacebookShareButton, TwitterIcon, TwitterShareButton } from 'react-share';
import classNames from 'classnames';
import Image from 'next/legacy/image';

import Copy from '~/assets/img/invite/copy.svg';
import { AnalyticsEventNames } from '~/constants/AnalyticsEventMap';
import { useGetFreeFeeReferralStatus } from '~/hooks/queries/portfolios/managed/useGetFreeFeeReferralStatus';
import { useTracking } from '~/hooks/tracking/useTracking';
import { Flex, Text } from '~/vinovest-design-system/components';

import { InviteBox } from './InviteBox';

export const ShareInviteLink = ({ link, inviteBoxClassnames }: { link: string; inviteBoxClassnames?: string }) => {
    const { data: referral } = useGetFreeFeeReferralStatus();
    const { captureEvent } = useTracking(AnalyticsEventNames.ReferralAction);
    const [copyClicked, setCopyClicked] = useState(false);

    const onCopyClick = async () => {
        captureEvent(AnalyticsEventNames.ReferralAction, { action: 'Copy URL to clipboard' } as any);
        setCopyClicked(true);
        await navigator.clipboard.writeText(referral?.shareUrl ?? '');
    };

    useEffect(() => {
        setTimeout(() => {
            if (copyClicked) setCopyClicked(false);
        }, 900);
    }, [copyClicked]);

    const facebookShare = useCallback(() => {
        captureEvent(undefined as any, { action: `Click Facebook share button` });
    }, [captureEvent]);
    const twitterShare = useCallback(() => {
        captureEvent(undefined as any, { action: `Click Twitter share button` });
    }, [captureEvent]);
    const emailShare = useCallback(() => {
        captureEvent(undefined as any, { action: `Click emailShare share button` });
    }, [captureEvent]);

    return (
        <div className={'flex w-full'}>
            <InviteBox
                className={classNames('w-full h-72 justify-around max-h-[169px] mt-[24px]', inviteBoxClassnames)}
            >
                <Flex>
                    <Text className={'text-background-70 text-12 leading-[21px] tracking-[0.005em] text-neutrals-500'}>
                        Share your referral link
                    </Text>
                </Flex>
                <div>
                    <div className={'relative z-0 inline-flex shadow-sm w-full h-12'}>
                        <input
                            type={'text'}
                            value={link}
                            disabled
                            className={
                                'relative flex-1 inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-16 font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
                            }
                        />
                        <button
                            aria-label={'Copy to clipboard'}
                            type={'button'}
                            onClick={onCopyClick}
                            className={
                                '-ml-px text-brown-400 relative text-14 justify-center align-center font-mono uppercase inline-flex items-center px-3 py-2 border-r border-t border-b border-gray-300 bg-white hover:bg-gray-50 focus:z-10 focus:outline-none '
                            }
                        >
                            <Image src={Copy} />
                        </button>
                    </div>
                    <Text className={'text-16 h-6 pt-2 uppercase font-mono'}>{copyClicked && 'Copied!'}</Text>
                </div>
                <Text className={'text-background-70 text-12 leading-[21px] tracking-[0.005em] text-neutrals-500'}>
                    Share via social media
                </Text>
                <Flex classnames={'justify-between'}>
                    <Flex classnames={'w-1/3 flex-grow justify-center bg-[#242E35] rounded-md max-h-[40px]'}>
                        <EmailShareButton
                            beforeOnClick={emailShare}
                            className={'w-full bg-[#242E35]'}
                            subject={'Check out Vinovest!'}
                            body={
                                'Diversify your portfolio by investing in rare wine and whiskey through Vinovest! Sign up using my invite link and receive 3 months of free fees!'
                            }
                            url={link}
                        >
                            <div className={'flex justify-center items-center ml-2'}>
                                <div className={'flex flex-col justify-center self-center'}>
                                    <svg
                                        width={'12'}
                                        height={'10'}
                                        viewBox={'0 0 12 10'}
                                        fill={'none'}
                                        xmlns={'http://www.w3.org/2000/svg'}
                                    >
                                        <path
                                            fillRule={'evenodd'}
                                            clipRule={'evenodd'}
                                            d={
                                                'M10.7143 0H0.714286C0.285714 0 0 0.285714 0 0.714286V1.71429L5.71429 4.92857L11.4286 1.78571V0.714286C11.4286 0.285714 11.1429 0 10.7143 0'
                                            }
                                            fill={'white'}
                                        />
                                        <path
                                            fillRule={'evenodd'}
                                            clipRule={'evenodd'}
                                            d={
                                                'M5.35714 6.35742L0 3.35742V9.28599C0 9.71456 0.285714 10.0003 0.714286 10.0003H10.7143C11.1429 10.0003 11.4286 9.71456 11.4286 9.28599V3.35742L6.07143 6.35742C5.87143 6.45742 5.55714 6.45742 5.35714 6.35742'
                                            }
                                            fill={'white'}
                                        />
                                    </svg>
                                </div>

                                <Text
                                    color={'white'}
                                    classnames={
                                        'ml-2 text-12 flex-col align-center justify-center self-center pr-4 text-white'
                                    }
                                >
                                    Email
                                </Text>
                            </div>
                        </EmailShareButton>
                    </Flex>
                    <Flex classnames={'w-1/3 justify-center bg-[#00aced] mx-2 h-12 rounded-md max-h-[40px]'}>
                        <TwitterShareButton
                            onClick={twitterShare}
                            className={'w-full'}
                            title={
                                'Diversify your portfolio by investing in rare wine and whiskey through Vinovest! Sign up using my invite link and receive 3 months of free fees!'
                            }
                            url={link}
                            hashtags={['wine', 'finewine', 'investing']}
                        >
                            <span className={'flex justify-center'}>
                                <TwitterIcon size={'24px'} />
                                <Text
                                    color={'white'}
                                    classnames={
                                        'text-12 flex-col align-center justify-center self-center pr-4 text-white'
                                    }
                                >
                                    Twitter
                                </Text>
                            </span>
                        </TwitterShareButton>
                    </Flex>
                    <Flex classnames={'w-1/3 justify-center bg-[#3b5998] rounded-md items-center max-h-[40px]'}>
                        <FacebookShareButton
                            onClick={facebookShare}
                            className={'w-full'}
                            title={
                                'Diversify your portfolio by investing in rare wine and whiskey through Vinovest! Sign up using my invite link and receive 3 months of free fees!'
                            }
                            url={referral?.shareUrl ?? ''}
                        >
                            <span className={'flex justify-center items-center'}>
                                <div className={'mx-2'}>
                                    <svg
                                        width={'14'}
                                        height={'14'}
                                        viewBox={'0 0 14 14'}
                                        fill={'none'}
                                        xmlns={'http://www.w3.org/2000/svg'}
                                    >
                                        <path
                                            fillRule={'evenodd'}
                                            clipRule={'evenodd'}
                                            d={
                                                'M0.5 6.80521C0.5 3.18243 3.33806 0.5 7.00008 0.5C10.6621 0.5 13.5002 3.18243 13.5002 6.80521C13.5002 10.428 10.6621 13.1104 7.00008 13.1104C6.34223 13.1104 5.71053 13.0241 5.11807 12.8606C5.00298 12.8292 4.88134 12.8384 4.77148 12.8868L3.48193 13.4557C3.1445 13.6035 2.76391 13.3641 2.75214 12.9953L2.71683 11.8392C2.7116 11.6966 2.64751 11.5645 2.54158 11.4691C1.27687 10.3378 0.5 8.70031 0.5 6.80521ZM5.00697 5.62041L3.09749 8.64943C2.91308 8.93977 3.27144 9.26805 3.54478 9.0601L5.59682 7.50243C5.73545 7.3978 5.9264 7.39649 6.06634 7.50112L7.58477 8.64027C8.03991 8.98162 8.69123 8.8613 8.99465 8.38001L10.9028 5.3523C11.0872 5.06196 10.7289 4.73368 10.4555 4.94163L8.4048 6.49799C8.26617 6.60262 8.07522 6.60393 7.93528 6.4993L6.41685 5.36015C5.96171 5.0188 5.3104 5.13912 5.00697 5.62041Z'
                                            }
                                            fill={'white'}
                                        />
                                    </svg>
                                </div>

                                <Text
                                    color={'white'}
                                    classnames={
                                        'text-12 flex-col align-center justify-center self-center pr-4 text-white'
                                    }
                                >
                                    Messenger
                                </Text>
                            </span>
                        </FacebookShareButton>
                    </Flex>
                </Flex>
            </InviteBox>
        </div>
    );
};
