import React, { PropsWithChildren } from 'react';

const ContentContainer: React.FC<PropsWithChildren<unknown>> = ({ children }: PropsWithChildren<unknown>) => {
    return (
        <div className={'flex-grow flex-shrink-0 flex-col'} id={'main-content'}>
            {children}
        </div>
    );
};

export default ContentContainer;
