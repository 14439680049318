import React from 'react';

import { ChangeCurrencyCode } from '~/components/modules/Currency/ChangeCurrencyCode';

import data from './footer.data';
import { BottomWrapper, FooterTitle, NavigationColumnsWrapper, NavWrapper, StyledNavigation } from './Footer.styled';
import FooterSocial from './FooterSocial';
import Link from 'next/link';

interface NavigationColumnProps {
    title: string;
    items: Array<{
        title: string;
        path: string;
        isLink: boolean;
    }>;
}

const NavigationColumn = ({ title, items }: NavigationColumnProps) => (
    <div>
        <FooterTitle className={'text-brown-300'}>{title}</FooterTitle>
        <StyledNavigation>
            {items.map((nav) => {
                return (
                    <Link href={nav.path} key={nav.title}>
                        {nav.title}
                    </Link>
                );
            })}
        </StyledNavigation>
    </div>
);

const FooterNavigation: React.FC = () => {
    return (
        <>
            <NavWrapper>
                <NavigationColumnsWrapper>
                    {data.map(({ title, navItems }) => {
                        return <NavigationColumn key={title} items={navItems} title={title} />;
                    })}
                </NavigationColumnsWrapper>
                <BottomWrapper>
                    <ChangeCurrencyCode />
                    <FooterSocial />
                </BottomWrapper>
            </NavWrapper>
        </>
    );
};

export default FooterNavigation;
