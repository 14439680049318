import { useGetFreeFeeReferralStatus } from '~/hooks/queries/portfolios/managed/useGetFreeFeeReferralStatus';
import { Card, Text } from '~/vinovest-design-system/components';

import { ShareInviteLink } from './ShareInviteLink';

export const ReferralBox: React.FC<Record<string, never>> = () => {
    const { data: referalStatus } = useGetFreeFeeReferralStatus();

    return (
        <Card classnames={'bg-gray-150 rounded-lg pt-[30px] px-0'} shadow={'none'} allPadding={'p-1'}>
            <div className={'grid grid-cols-2 !md:min-width-[539px] !md:width-[539px]'}>
                <div className={'flex justify-center items-center border-r-solid border-r-[1px] border-r-[#CACCCE]'}>
                    <div className={'flex-col justify-center items-center border-solid border-black border-1'}>
                        <Text classnames={'flex self-center items-center justify-center text-platinum-800'}>
                            Successful Invites
                        </Text>
                        <Text classnames={'flex self-center items-center justify-center text-platinum-800'}>
                            {referalStatus?.referralCount ?? 0}
                        </Text>
                    </div>
                </div>

                <div className={'flex-col justify-center items-center'}>
                    <Text classnames={'flex self-center items-center justify-center text-platinum-800'}>
                        Months No Fees
                    </Text>
                    <Text classnames={'flex self-center items-center justify-center text-platinum-800'}>
                        {(referalStatus?.rewards?.length ?? 0) * 3}
                    </Text>
                </div>
            </div>
            <div className={'mt-2 border-t-solid border-t-[1px] border-t-[#CACCCE] mx-[14px] md:mx-[46px]'} />
            <ShareInviteLink link={referalStatus?.shareUrl ?? ''} />
        </Card>
    );
};
