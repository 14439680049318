export const ROUTE_PATHS = {
    home: '/',
    portfolio: '/dashboard',
    account: '/account',
    invite: '/invite',
    login: '/login',
    signup: '/signup',
    deposit: '/deposit',
    transactions: '/transactions',
    documentsPage: '/documents',
    beforeYouRequest: '/before-you-liquidate-your-portfolio',
    liquidation: '/liquidation',
    scheduledWines: '/scheduled-wines',
    print: '/print',

    whyWine: '/why-wine',
    whyWhiskey: '/whiskey-investment',
    aboutUs: '/about-us',
    council: '/advisory-council',
    trackRecord: '/track-record',
    wineDirectory: '/trade',
    contactUs: '/contact-us',
    howItWorks: '/how-it-works',
    sustainability: '/sustainability',
    pricing: '/pricing',
    adSlider: '/advisory-council/:id',
    satisfaction: '/the-vinovest-satisfaction-guarantee',
    wineFutures: '/wine-futures',
    terms: '/terms-conditions',
    vinovestHome: '/home',
    vinovest100Index: '/vinovest-100-index',
    privacyPolicy: '/privacy-policy',
    careers: '/careers',
    contactSupport: '/contact-support',
    help: '/help',
    press: '/press',
    helpCategory: '/help-category',
    blog: '/blog',
    accessibility: '/accessibility',
    // old paths for redirecting purposes
    support: '/support',
    supportCategory: '/support-category',
    community: '/community'
};
