import { EVENTS } from 'react-joyride';

import { OnboardingType } from '~/components/modules/OnboardingTour/OnboardingTourContainer';
import { AnalyticsEventMap, AnalyticsEventNames } from '~/constants/AnalyticsEventMap';
import { LocalStorageHelper, SessionStorageHelper } from '~/helpers/StorageHelper';

/**
 * Helper to read, write and update keys for a specific onboarding variant.
 * @see {@link OnboardingType}
 */
export class OnboardingHelper<T extends OnboardingType> {
    constructor(type: T) {
        this.onboardingType = type;
    }

    public get getStorageHelper() {
        if (this.onboardingType === OnboardingType.Dashboard) {
            return SessionStorageHelper;
        }
        return LocalStorageHelper;
    }

    /** Get the onboarding helper of a specific variant. */
    public static getHelper(type: OnboardingType) {
        return new OnboardingHelper(type);
    }

    /** Convenience getter for the dashboard onboarding tour helper */
    public static get dashboard() {
        return this.getHelper(OnboardingType.Dashboard);
    }

    /** Convenience getter for the trading onboarding tour helper */
    public static get trading() {
        return this.getHelper(OnboardingType.Trading);
    }

    /** Convenience getter for the welcome onboarding modal helper */
    public static get welcome() {
        return this.getHelper(OnboardingType.Welcome);
    }

    /** local storage key for the the tour progress step/index */
    public get tourIndexKey(): string {
        return `${this.onboardingType}-tour-index`;
    }

    /** local storage key to store tour completion */
    public get tourCompletedKey(): string {
        return `${this.onboardingType}-tour-completed`;
    }

    /** Set onboarding experience as completed */
    public setCompleted(): void {
        if (this.checkWindow) {
            this.getStorageHelper.setItem(this.tourCompletedKey, 'true');
        }
    }

    /** Get onboarding experience completion status */
    public getCompleted(): boolean {
        return this.checkWindow && this.getStorageHelper.getItem(this.tourCompletedKey) === 'true';
    }

    /** Remove local storage key for onboarding completion */
    public removeCompleted() {
        if (this.checkWindow) {
            this.getStorageHelper.removeItem(this.tourCompletedKey);
        }
    }

    /** Set user onboarding progress (index) in the onboarding experience. */
    public setIndex(valueToSet: string) {
        this.checkWindow && this.getStorageHelper.setItem(this.tourIndexKey, valueToSet);
    }

    /** Get user onboarding progress (index) of where the user is in the onboarding experience. */
    public getIndex(): number {
        if (this.checkWindow) {
            const existingIndex = this.getStorageHelper.getItem(this.tourIndexKey);
            const parsedCurrentIndex = !existingIndex ? 0 : Number.parseInt(existingIndex, 10);

            // Set index into local store if there is nothing there
            !parsedCurrentIndex && this.setIndex('0');

            return parsedCurrentIndex;
        }
        return 0;
    }

    /** Remove user onboarding progress in local storage */
    public removeIndex() {
        this.checkWindow && this.getStorageHelper.removeItem(this.tourIndexKey);
    }

    /** Handle updating local storage and returning the new tour step index in response to a user progressing in the tour. */
    public handleTourUpdate(
        index: number,
        tourEventType: string,
        captureEvent: (event: keyof AnalyticsEventMap, metaData: any) => any
    ): number {
        if (!this.checkWindow) {
            return index;
        }

        const targetNotFound = tourEventType === EVENTS.TARGET_NOT_FOUND;
        const stepAfter = tourEventType === EVENTS.STEP_AFTER;
        const tourEnd = tourEventType === EVENTS.TOUR_END;

        // Next step
        if (stepAfter || targetNotFound) {
            const parsedCurrentIndex = this.getIndex();

            const eventStage = targetNotFound ? 'error' : `${parsedCurrentIndex}`;
            if (this.onboardingType === OnboardingType.Trading) {
                captureEvent(AnalyticsEventNames.TooltipNext, eventStage);
            }

            const step = (parsedCurrentIndex + 1).toString();
            this.setIndex(step);

            return parsedCurrentIndex + 1;
        }

        // Tour Done
        if (tourEnd) {
            if (this.onboardingType === OnboardingType.Trading) {
                captureEvent(AnalyticsEventNames.SkipTradingTooltip, { stage: 'finished' });
            }

            this.removeIndex();
            this.setCompleted();
        }

        return index;
    }

    /** What kind of variant the current instantiation is */
    public onboardingType: T;

    /** Check for existance of window */
    private get checkWindow() {
        return typeof window !== 'undefined' && window.localStorage;
    }
}
