export const defaultPathsToRegexpOptions = {
    exact: true
};

export const pathToRegexp = (pathnames: string[], exact: boolean) => {
    const pathsForRegexp = pathnames.map((pathname) => {
        const pathWithParam = pathname
            .split('/')
            .filter(Boolean)
            .map((path) => {
                if (path[0] === '[') {
                    return '.+?';
                }
                return path;
            });
        return pathWithParam.join('\\/');
    });

    return new RegExp(`\\/(${pathsForRegexp.join('|')})${exact ? '' : '.*'}`);
};
