import { useMemo } from 'react';
import { useRouter } from 'next/router';

import { AnalyticsEventNames } from '~/constants/AnalyticsEventMap';
import { PosthogHelper } from '~/helpers/PosthogHelper';
import { ReferralGlobal, UrlModalHelper } from '~/helpers/UrlModalHelper';
import { baseRoutes } from '~/routes/base';
import { whiskeyRoutes } from '~/routes/whiskey';
import { MenuLink } from '~/vinovest-design-system/components/Navigation';

export const WhiskeyNavigation = () => {
    const router = useRouter();
    const whiskey = useMemo(() => router.pathname.includes(baseRoutes.whiskey.href), [router.pathname]);
    return (
        <>
            <MenuLink
                className={`cursor-pointer flex items-center text-20 md:text-16 py-5 px-[16px] !ml-0 ${whiskey ? 'text-brown-300' : ''}`}
                link={whiskeyRoutes.reserve.href}
                variant={`custom`}
                activeBorderColor={'border-b border-brown-300'}
            >
                Reserve
            </MenuLink>
            <MenuLink
                className={`cursor-pointer flex items-center text-20 md:text-16 py-5 px-[16px] !ml-0 ${whiskey ? 'text-brown-300' : ''}`}
                link={whiskeyRoutes.portfolio.href}
                variant={`custom`}
                activeBorderColor={'border-b border-brown-300'}
                onClick={() => PosthogHelper.captureEvent(AnalyticsEventNames.SelectPortfolio, { type: 'whiskey' })}
            >
                Portfolio
            </MenuLink>
            <MenuLink
                className={`cursor-pointer flex items-center text-20 md:text-16 py-5 px-[16px] !ml-0 ${whiskey ? 'text-brown-300' : ''}`}
                link={whiskeyRoutes.activity.href}
                variant={`custom`}
                activeBorderColor={'border-b border-brown-300'}
                onClick={() => PosthogHelper.captureEvent(AnalyticsEventNames.SelectActivity, { type: 'whiskey' })}
            >
                Activity
            </MenuLink>
            <MenuLink
                track={{
                    eventName: AnalyticsEventNames.ReferralAction,
                    metaData: { action: 'navigate to invite' }
                }}
                className={`cursor-pointer flex items-center text-20 md:text-16 py-5 px-[16px] !ml-0 ${whiskey ? 'text-brown-300' : ''}`}
                onClick={() => {
                    UrlModalHelper.openModal(router, ReferralGlobal);
                }}
            >
                Invite
            </MenuLink>
        </>
    );
};
