import { useMemo } from 'react';

import { useManagedPortfolioQuery } from '~/hooks/queries/portfolios/managed/useManagedPortfolioQuery';
import { useTradingPortfolioBottles } from '~/hooks/queries/portfolios/trading/useTradingPortfolioBottles';
import { useTradingPortfolioQuery } from '~/hooks/queries/portfolios/trading/useTradingPortfolioQuery';

/**
 * Result of the useGetFundingStatus hook to determine if a user has ever funded their account
 * or for a particular portfolio.
 */
interface UseGetFundingStatusResult {
    tradingFunded: boolean;
    managedFunded: boolean;
    funded: boolean;
    isLoading: boolean;
}

/**
 * Get funding status of a users portfolio for: managed, trading and either.
 */
export const useGetFundingStatus = (): UseGetFundingStatusResult => {
    const {
        data: managedPortfolio,
        isSuccess: managedPortfolioSuccess,
        isFetched: managedLoaded
    } = useManagedPortfolioQuery();
    const {
        data: tradingPortfolio,
        isSuccess: tradingPortfolioSuccess,
        isFetched: tradingLoaded
    } = useTradingPortfolioQuery();
    const {
        data: tradingPortfolioBottles,
        isSuccess: tradingPortfolioBottleSuccess,
        isFetched: tradingBottleLoaded
    } = useTradingPortfolioBottles();

    const managedPortfolioIsFunded = useMemo(() => {
        if (managedPortfolioSuccess) {
            const managedBottleQuantity = managedPortfolio?.bottleCount || 0;
            const managedCashBalance = managedPortfolio?.cash?.amount || 0;
            return managedBottleQuantity + managedCashBalance > 0;
        }

        return false;
    }, [managedPortfolio, managedPortfolioSuccess]);

    const tradingPortfolioIsFunded = useMemo(() => {
        if (tradingPortfolioSuccess && tradingPortfolioBottleSuccess) {
            const tradingBottleQuantity = tradingPortfolioBottles?.bottles?.length || 0;
            const tradingCashBalance = tradingPortfolio?.cashBalance?.amount || 0;
            return tradingBottleQuantity + tradingCashBalance > 0;
        }

        return false;
    }, [tradingPortfolio, tradingPortfolioSuccess, tradingPortfolioBottleSuccess]);

    return {
        tradingFunded: tradingPortfolioIsFunded,
        managedFunded: managedPortfolioIsFunded,
        funded: managedPortfolioIsFunded || tradingPortfolioIsFunded,
        isLoading: !managedLoaded || !tradingLoaded || !tradingBottleLoaded
    };
};
