import { createRoute } from 'next-typed-routes';

/**
 * Nested help routes
 */
export enum HelpRoutes {
    HelpQuestion = '/help/[slug]',
    HelpCategory = '/help-category/[slug]'
}

/** Help links for use with next router */
export const helpRoutes = {
    helpQuestion: createRoute(HelpRoutes.HelpQuestion),
    helpCategory: createRoute(HelpRoutes.HelpCategory)
};
