import { NextRouter, useRouter } from 'next/router';

import { AnalyticsEventNames } from '~/constants/AnalyticsEventMap';
import { PosthogHelper } from '~/helpers/PosthogHelper';
import { ReferralGlobal, UrlModalHelper } from '~/helpers/UrlModalHelper';
import { BaseRoutes, ManagedRoutes } from '~/routes/applicationRoutes';
import { matchPathname } from '~/utils/pathnames/matchPathname';
import { MenuLink } from '~/vinovest-design-system/components/Navigation';

export const showManagedNavigation = (router: NextRouter): boolean => {
    const managedPaths = [
        ManagedRoutes.Documents,
        ManagedRoutes.Portfolio,
        BaseRoutes.Managed,
        BaseRoutes.Invite,
        BaseRoutes.Help,
        ManagedRoutes.Transactions,
        ManagedRoutes.Liquidation,
        ManagedRoutes.BeforeYouLiquidate,
        BaseRoutes.Account
    ];
    return matchPathname(managedPaths, router.asPath, false);
};

export const ManagedNavigation = () => {
    const router = useRouter();
    if (!showManagedNavigation(router)) return null;

    return (
        <>
            <MenuLink
                classnames={'text-20 md:text-16 py-5 px-[16px] items-center !ml-0'}
                link={BaseRoutes.Managed}
                onClick={() => PosthogHelper.captureEvent(AnalyticsEventNames.SelectOverview)}
            >
                Overview
            </MenuLink>
            <MenuLink
                classnames={'text-20 md:text-16 py-5 px-[16px] items-center !ml-0'}
                link={ManagedRoutes.Portfolio}
                onClick={() => PosthogHelper.captureEvent(AnalyticsEventNames.SelectPortfolio, { type: 'wine' })}
            >
                Portfolio
            </MenuLink>
            <MenuLink
                classnames={'text-20 md:text-16 py-5 px-[16px] items-center !ml-0'}
                link={ManagedRoutes.Transactions}
                onClick={() => PosthogHelper.captureEvent(AnalyticsEventNames.SelectActivity, { type: 'wine' })}
            >
                Activity
            </MenuLink>
            <MenuLink
                classnames={'text-20 md:text-16 py-5 px-[16px] items-center !ml-0'}
                link={ManagedRoutes.Documents}
                onClick={() => PosthogHelper.captureEvent(AnalyticsEventNames.SelectDocuments, { type: 'wine' })}
            >
                Documents
            </MenuLink>
            <MenuLink
                track={{
                    eventName: AnalyticsEventNames.ReferralAction,
                    metaData: { action: 'navigate to invite' }
                }}
                classnames={'text-20 md:text-16 py-5 px-[16px] items-center !ml-0'}
                onClick={() => {
                    UrlModalHelper.openModal(router, ReferralGlobal);
                }}
            >
                Invite
            </MenuLink>
            <MenuLink
                classnames={'text-20 md:text-16 py-5 px-[16px] items-center !ml-0'}
                link={BaseRoutes.Help}
                onClick={() => PosthogHelper.captureEvent(AnalyticsEventNames.SelectFaq)}
            >
                FAQ
            </MenuLink>
        </>
    );
};
