/* eslint-disable no-nested-ternary */
import { useRouter } from 'next/router';

import { splitPathArray } from '~/utils/routesHelper';
import { createContext, useContext } from 'react';

interface PageTitle {
    backTo: string;
    pageTitle: string;
}

export const PageTitleContext = createContext({} as PageTitle);

/** Get page title by current route. */
export const useCustomPageTitle: () => PageTitle = () => {
    const router = useRouter();
    const ctx = useContext(PageTitleContext);

    if (ctx.backTo && ctx.pageTitle) {
        return ctx;
    }

    const paths = splitPathArray(router?.asPath, router?.pathname);
    let backTo = '';
    let pageTitle = '';

    switch (paths?.length) {
        case 1:
            backTo = paths[0].href !== '/dashboard' || paths[0].title !== 'help' ? '/dashboard' : '';
            pageTitle =
                paths[0].title === 'managed'
                    ? 'overview'
                    : paths[0].title === 'help'
                      ? 'FAQ'
                      : paths[0].title === 'dashboard'
                        ? ''
                        : paths[0].title === 'scheduled-wines'
                          ? 'Liquidation'
                          : paths[paths.length - 1].title?.split('?')[0];
            break;
        case 2:
            backTo = '/dashboard';
            pageTitle =
                paths[paths.length - 1].title === 'transactions'
                    ? 'activity'
                    : paths[paths.length - 1].title?.split('?')[0];
            break;
        case 3:
            backTo = paths[paths.length - 2].href;
            pageTitle =
                paths[1].title === 'collections'
                    ? 'collection details'
                    : paths[1].title === 'portfolio'
                      ? 'wine details'
                      : paths[paths.length - 1].title?.split('?')[0];
            break;
        case 4:
            backTo = paths[paths.length - 3].href;
            pageTitle =
                paths[1].title === 'marketplace' ? 'wine details' : paths[paths.length - 1].title?.split('?')[0];
            break;
        case 5:
            backTo = paths[paths.length - 3].href;
            pageTitle =
                paths[1].title === 'collections' ? 'wine details' : paths[paths.length - 1].title?.split('?')[0];
            break;

        default:
            backTo = '/dashboard';
    }

    return { backTo, pageTitle };
};
