import { NextRouter } from 'next/router';

export const ConnectSourceModalKey = 'connectPaymentMethod';
export const SelectAPathModalKey = 'selectPath';
export const DepositTransferModalKey = 'depositTransfer';
export const InitialDepositSelection = 'initialDepositSelection';
export const TierPlanComparison = 'tierPlanComparison';
export const BookACallOnboarding = 'bookACallOnboarding';
export const AllPlans = 'allPlans';
export const ReferralOnboarding = 'referralOnboarding';
export const ReferralGlobal = 'referralGlobal';
export const SinglePlan = 'singlePlan';

/** Modal keys that can be used to open/close modals. */
const modalKeys = [
    ConnectSourceModalKey,
    DepositTransferModalKey,
    SelectAPathModalKey,
    InitialDepositSelection,
    BookACallOnboarding,
    TierPlanComparison,
    ReferralOnboarding,
    ReferralGlobal,
    AllPlans
];

let routePathUrl: string | null = '';

export enum ModalEvents {
    Closed = 'closed',
    Opened = 'opened'
}

export interface ModalEventDetails {
    modalKey: string;
    success?: boolean;
}
/**
 * The url modal helper is designed to work with modals that are conditionally
 * shown or hidden across the site via the URL. This object provides the basic
 * necessities to open / close and determine visibility of those modals.
 */
export const UrlModalHelper = {
    /**
     * Used to determine if there is already a modal open on the page that is
     * keyed off of the URL parameters. This is helpful in determining whether or not
     * to show another modal
     * @returns true if there are any URL based modals open
     */
    isAnyModalOpen: () => {
        if (typeof window !== 'undefined') {
            const searchString = window.location.search;
            const params = new URLSearchParams(searchString.slice(1));
            return modalKeys.some((modalKey) => {
                return !!params.get(modalKey);
            });
        }
        return false;
    },
    /**
     * Used to determine if a provided modal key is set in the URL. This
     * determines whether or not URL based modals are visible or not.
     * @param modalKey the modal key to check for in the URL
     * @returns true if the given modal is open, false otherwise
     */
    isModalOpen: (modalKey: string) => {
        if (typeof window !== 'undefined') {
            const params = new URLSearchParams(window.location.search.slice(1));
            if (params.get(modalKey)) {
                return true;
            }
        }
        return false;
    },
    /**
     * Sets the URL parameter for the given modal key that is used to conditionally render a modal
     * @param router the NextRouter used to change the URL to show a modal
     * @param modalKey the modal key for the modal to show
     * @param routePath
     */
    openModal: (router: NextRouter, modalKey: string, routePath?: string) => {
        const openEvent = new CustomEvent(ModalEvents.Opened, { detail: { modalKey } });
        if (!router.query[modalKey]) {
            router.query[modalKey] = '1';
            void router.push(router);
            window?.dispatchEvent(openEvent);
        }
        routePathUrl = routePath ?? null;
    },
    /**
     * Unsets the URL parameter for the given modal key and should cause the modal to hide
     * @param router the NextRouter used to change the URL to show a modal
     * @param modalKey the modal key for the modal to show
     * @param success
     */
    closeModal: (router: NextRouter, modalKey: string, success?: boolean) => {
        const closedEvent = new CustomEvent<ModalEventDetails>(ModalEvents.Closed, {
            detail: {
                ...(success ? { success, modalKey } : { modalKey })
            }
        });
        if (router.query[modalKey]) {
            dispatchEvent(closedEvent);
            delete router.query[modalKey];
            if (routePathUrl != null && success) {
                void router.push(routePathUrl);
            } else {
                void router.push(router);
            }
        }
    }
};
