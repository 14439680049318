import { useMemo } from 'react';
import { Step } from 'react-joyride';

import { SHARED_STEP_OPTIONS } from '~/components/modules/OnboardingTour/components/OnboardingTourStepProps';
import { OnboardingTourContainer, OnboardingType } from '~/components/modules/OnboardingTour/OnboardingTourContainer';
import { useWindowSize } from '~/hooks/useWindowSize';
import { getTourStepTargetSelector } from '~/utils/onboardingTourUtils';

export const DASHBOARD_ONBOARDING_TOUR_STEP_ONE_BASE_SELECTOR = 'overall-balance';

/** Onboarding tour for the trading dashboard */
export const DashboardOnboardingTour: React.FC = () => {
    const { isMobile } = useWindowSize();
    const steps = useMemo(() => getTourSteps(isMobile), [isMobile]);

    return <>{<OnboardingTourContainer steps={steps} variant={OnboardingType.Dashboard} />}</>;
};

const getTourSteps = (isMobile = false): Step[] => [
    {
        ...SHARED_STEP_OPTIONS,
        target: `#${getTourStepTargetSelector(0, DASHBOARD_ONBOARDING_TOUR_STEP_ONE_BASE_SELECTOR, isMobile)}`,
        content: 'Connect a payment account to fund your Vinovest account before you buy your first wine.',
        placement: 'bottom',
        disableOverlayClose: true,
        styles: {
            spotlight: {
                backgroundColor: 'rgba(255,255,255,0.3)'
            }
        }
    }
];

export default DashboardOnboardingTour;
