import { createRoute } from 'next-typed-routes';

/**
 * Nested Whiskey routes
 */
export enum WhiskeyRoutes {
    Reserve = '/whiskey/reserve',
    Portfolio = '/whiskey/portfolio',
    Activity = '/whiskey/activity',
    Guide = '/whiskey/guide'
}

/** Whiskey routes for use with next router */
export const whiskeyRoutes = {
    reserve: createRoute(WhiskeyRoutes.Reserve),
    portfolio: createRoute(WhiskeyRoutes.Portfolio),
    activity: createRoute(WhiskeyRoutes.Activity),
    guide: createRoute(WhiskeyRoutes.Guide)
};
