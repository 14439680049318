import { PropsWithChildren } from 'react';
import classNames from 'classnames';

import { WindowSize } from '~/constants/mediaQueries';
import { useWindowSize } from '~/hooks/useWindowSize';
import { getTourStepTargetSelector } from '~/utils/onboardingTourUtils';
import { Box } from '~/vinovest-design-system/components';

interface OnboardingTourTargetContainerProps {
    /** number indicating the step within the tour */
    stepNumber: number;
    /** base selector to use when creating the target element container ID */
    baseTargetSelector: string;
    /** the allowed window size in which the given target element will be wrapped */
    maximumAllowedScreenSize: WindowSize;
    /** Optional classes to add to the container element */
    containerClassNames?: string;
    /** Only do onboarding tour if the account is unfunded */
    needsOnboarding?: boolean;
}

/** Container component for a target element for a step in an onboarding tour */
export const OnboardingTourTargetContainer: React.FC<PropsWithChildren<OnboardingTourTargetContainerProps>> = ({
    stepNumber,
    baseTargetSelector,
    maximumAllowedScreenSize,
    containerClassNames,
    children,
    needsOnboarding
}: PropsWithChildren<OnboardingTourTargetContainerProps>) => {
    const { isMobile, isDesktop } = useWindowSize();

    let isInvalidScreenSize = false;

    switch (maximumAllowedScreenSize) {
        case 'mobile': {
            isInvalidScreenSize = !isMobile;
            break;
        }
        case 'tablet': {
            isInvalidScreenSize = isDesktop;
            break;
        }
        case 'desktop': {
            isInvalidScreenSize = !isDesktop;
            break;
        }
        default: {
            break;
        }
    }

    return isInvalidScreenSize || !needsOnboarding ? (
        <>{children}</>
    ) : (
        <Box
            id={getTourStepTargetSelector(stepNumber, baseTargetSelector, isMobile)}
            classnames={`${children ? classNames('w-initial', containerClassNames) : 'hidden'}`}
        >
            {children}
        </Box>
    );
};

export default OnboardingTourTargetContainer;
