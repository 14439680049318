import { Fragment, useCallback, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { SelectorIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { useRouter } from 'next/router';

import { useSwitchCurrencyCodes } from '~/hooks/useSwitchCurrencyCodes';
import { Box, Button, Flex, Heading, Text } from '~/vinovest-design-system/components/';
import { Modal } from '~/vinovest-design-system/components/Modal';

export const acceptedCurrencies = {
    USD: '$ US Dollars (USD)',
    HKD: 'HK$ Hong Kong Dollars (HKD)',
    GBP: '£ Great British Pounds (GBP)',
    CAD: 'C$ Canadian Dollars (CAD)'
};

export const ChangeCurrencyCode = () => {
    const router = useRouter();

    const [open, setOpen] = useState(false);
    const { selectedCurr, handleCurrChange, handleCancelCurr, handleSaveCurr } = useSwitchCurrencyCodes();

    const toggleModal = useCallback((e: React.MouseEvent | React.KeyboardEvent) => {
        if ((e as React.KeyboardEvent).key === undefined || (e as React.KeyboardEvent).key === 'Enter') {
            setOpen((prevShowModal: boolean) => !prevShowModal);
            document.body.scrollTop = 0; // Safari
            document.documentElement.scrollTop = 0; // Rest
        }
    }, []);

    const onSaveClicked = () => {
        handleSaveCurr();
        setOpen(false);
        router.reload();
    };

    return (
        <>
            <Button variant={'brownBorderTransparent'} onClick={toggleModal}>
                {acceptedCurrencies[selectedCurr]}
            </Button>
            <Modal open={open} setOpen={setOpen} classnames={'max-w-md overflow-visible'}>
                <>
                    <Box classnames={'text-center'}>
                        <Heading level={'h4'}>Change your settings</Heading>
                        <Text classnames={'my-4'}>Choose your display currency</Text>
                    </Box>

                    <Listbox value={selectedCurr} onChange={handleCurrChange}>
                        {({ open: modalOpened }) => (
                            <>
                                <Text classnames={'uppercase text-14 mb-2'}>Currency</Text>
                                <div className={'mt-1 relative'}>
                                    <Listbox.Button
                                        className={
                                            'bg-white relative w-full border border-gray-300 pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                        }
                                    >
                                        <span className={'block truncate'}>{acceptedCurrencies[selectedCurr]}</span>
                                        <span
                                            className={
                                                'absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'
                                            }
                                        >
                                            <SelectorIcon className={'h-5 w-5 text-gray-400'} aria-hidden={'true'} />
                                        </span>
                                    </Listbox.Button>

                                    <Transition
                                        show={modalOpened}
                                        as={Fragment}
                                        leave={'transition ease-in duration-100'}
                                        leaveFrom={'opacity-100'}
                                        leaveTo={'opacity-0'}
                                    >
                                        <Listbox.Options
                                            className={
                                                'absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm'
                                            }
                                        >
                                            {(
                                                Object.keys(acceptedCurrencies) as (keyof typeof acceptedCurrencies)[]
                                            ).map((key: keyof typeof acceptedCurrencies) => (
                                                <Listbox.Option
                                                    key={key}
                                                    className={({ active }) =>
                                                        classNames(
                                                            active && 'bg-gray-200',
                                                            'cursor-default select-none relative mx-2 my-2 rounded-md text-neutrals-900'
                                                        )
                                                    }
                                                    value={key}
                                                >
                                                    {({ selected }) => (
                                                        <span
                                                            className={classNames(
                                                                selected && 'bg-gray-200',
                                                                'block truncate py-2 pl-3 rounded-tl-md rounded-md w-full'
                                                            )}
                                                        >
                                                            {acceptedCurrencies[key]}
                                                        </span>
                                                    )}
                                                </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </Transition>
                                </div>
                            </>
                        )}
                    </Listbox>

                    <Flex classnames={'gap-4 mt-5'}>
                        <Box classnames={'w-1/2'}>
                            <Button
                                onClick={() => {
                                    handleCancelCurr();
                                    setOpen(false);
                                }}
                                variant={'secondary'}
                                classnames={'w-full'}
                            >
                                Cancel
                            </Button>
                        </Box>
                        <Box classnames={'w-1/2'}>
                            <Button classnames={' w-full'} onClick={onSaveClicked}>
                                Save Changes
                            </Button>
                        </Box>
                    </Flex>
                </>
            </Modal>
        </>
    );
};
