import { ReferralBox } from '~/components/views/Invite/ReferralBox';
import { useUrlModal } from '~/components/views/Onboarding/PlanPortfolio/modals/DepositSelectionModal/useUrlModal';
import { ReferralGlobal } from '~/helpers/UrlModalHelper';
import { Heading, Modal, Text } from '~/vinovest-design-system/components';

export const ReferralGlobalModal: React.FC = () => {
    const { open, closeModal } = useUrlModal(ReferralGlobal);
    return (
        <Modal classnames={'max-w-[650px] w-11/12'} open={open} setOpen={closeModal} modalKey={ReferralGlobal}>
            <div className={'md:!px-8 md:!pt-6'}>
                <Heading
                    level={'h3'}
                    fontWeight={'font-normal'}
                    classnames={
                        'xs:!pt-4 sm:!pt-0 md:!pt-0 mb-4 mx-auto max-w-[530px] text-center text-transforn-none xs:!text-[24px] md:!text-[45px] !leading-snug'
                    }
                >
                    Get up to 4 months of free storage and insurance when you refer a friend
                </Heading>
                <Text classnames={'mb-6 mx-auto max-w-[500px] text-center text-[14px]'}>
                    Share the wealth. When you refer your friends to Vinovest, you'll both receive up to 4 months of
                    free storage and insurance.
                </Text>
                <ReferralBox />
                <Heading level={'h4'} classnames={'mt-6 !text-[24px]'}>
                    How It Works
                </Heading>
                <Text classnames={'font-bold mt-6 font-roslindale text-[18px]'}>Refer a friend</Text>
                <Text classnames={'text-[14px]'}>Refer a friend to Vinovest using your personal referral link</Text>

                <Text classnames={'font-bold mt-6 font-roslindale text-[18px]'}>Get rewards</Text>
                <Text classnames={'text-[14px] max-w-[500px]'}>
                    When your friend funds a managed account, you'll both get 3 months of free storage and insurance and
                    1 month of no trading fees. If your friend funds a trading account, you'll both get 1 month of no
                    managed and trading fees.
                </Text>

                <Text classnames={'font-bold mt-6 font-roslindale text-[18px]'}> Repeat and save</Text>
                <Text classnames={'text-[14px] mb-6'}>
                    There's no limit to how many friends you can refer or your potential savings
                </Text>
            </div>
        </Modal>
    );
};
